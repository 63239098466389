<template>
  <Layout>
    <main class="pt-5 pb-3">
      <div class="container">
        <div class="card memberStep">
          <div v-if="isLoading" class="preloader-container">
            <div class="preloader"></div>
          </div>
          <div v-else>
            <div class="card-body">
              <h2 class="mainTitleSize pt-3">Membership Application</h2>
              <div v-if="!showRadio" class="">
                <h4 class="text-center" v-if="hide_entering_message" style="color: green">
                  Welcome. Lets get started with your application
                </h4>
                <br v-if="hide_entering_message" />
                <div class="row">
                  <div class="col-lg-12 mb-3 mb-lg-0">
                    <div class="bg-theme p-3">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="adultRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                            <input type="radio" v-model="radioSelected1" value="adult" name="radioAdult"
                              class="inputRadioCstm d-none" id="adultRadio" />
                            <span class="radioCircleIcon">
                              <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                              <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                            </span>
                            <h4 class="font-size-14 text-white mb-0 ms-2">
                              Adult (Any person 18 years and over at the time of
                              application is considered an adult)
                            </h4>
                          </label>
                        </div>
                        <div class="col-md-6">
                          <label for="minorRadio" class="mb-0 p-relative border w-100 labelStyleRadioCstm">
                            <input type="radio" v-model="radioSelected1" value="minor" name="radioAdult"
                              class="inputRadioCstm d-none" id="minorRadio" />
                            <span class="radioCircleIcon">
                              <i class="mdi mdi-circle-outline text-white font-size-20"></i>
                              <i class="mdi mdi-checkbox-marked-circle text-white theme-check font-size-20"></i>
                            </span>
                            <h4 class="font-size-14 text-white mb-0 ms-2">
                              Minor (Any person under the age of 18 at the time
                              of application is considered a minor)
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <br />
                <div class="text-end">
                  <button @click="showRadio = true" ref="anotherButton" type="button" style="display: none"
                    class="btn btn-theme btn-custom-wdth">
                    Next
                  </button>
                  <button @click="submitDataInitial" type="button" class="btn btn-theme btn-custom-wdth">
                    Next
                  </button>
                </div>
              </div>

              <label v-if="showErrorMessage" style="color: red" class="font-size-18">Please Fill All The Required
                Field</label>
              <br />

              <template v-if="showRadio">
                <form-wizard @on-complete="onComplete" :start-index="startIndex" step-size="xs" color="#f48226"
                  @saveFormData="saveFormData">
                  <!-- :start-index=5 -->
                  <tab-content class="tab_1" title="" :before-change="handleBeforeChange">
                    <div class="text-main-title">
                      <button type="button" class="btn btn-theme button-position" v-on:click="resetFields()">
                        <i class="mdi mdi-arrow-left"></i> Back
                      </button>
                      <h2 class="titleStepForm">Personal Information</h2>
                    </div>

                    <div class="main-from-group my-4">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="groupFormStyle">
                            <div class="row">
                              <div class="col-12 mb-4">
                                <label class="font-size-14">Please Select Your Preferred Eastern Credit Union Branch
                                </label>
                                <div class="single-select2-cstm">
                                  <Select2 v-model="form.personal1.branchName" placeholder="Choose the branch name"
                                    :options="branchNameOpt" :class="{
                                      'is-invalid':
                                        v$.form.personal1.branchName.$error,
                                    }" />
                                  <div v-for="(personal1, index) in v$.form
                                      .personal1.branchName.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal1.$message">{{
                                      personal1.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <hr>
                              <div class="col-12">
                                <h3 class="formMainHeading">Applicant</h3>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Title</label>
                                  <div class="single-select2-cstm">
                                    <Select2 v-model="form.personal1.title" :options="titleNameOpt" :class="{
                                        'is-invalid':
                                          v$.form.personal1.title.$error,
                                      }" />
                                    <div v-for="(personal1, index) in v$.form
                                        .personal1.title.$errors" :key="index" class="invalid-feedback">
                                      <span v-if="personal1.$message">{{
                                        personal1.$message
                                        }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Surname</label>
                                  <input type="text" class="form-control" v-model="form.personal1.surname" :class="{
                                      'is-invalid':
                                        v$.form.personal1.surname.$error,
                                    }" />
                                  <div v-for="(personal1, index) in v$.form
                                      .personal1.surname.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal1.$message">{{
                                      personal1.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">First Name</label>
                                  <input type="text" v-model="form.personal1.firstName" class="form-control" :class="{
                                      'is-invalid':
                                        v$.form.personal1.firstName.$error,
                                    }" />
                                  <div v-for="(personal1, index) in v$.form
                                      .personal1.firstName.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal1.$message">{{
                                      personal1.$message
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 mb-3">
                                <div class="d-block">
                                  <label class="font-size-14">Middle Name</label>
                                  <input type="text" v-model="form.personal1.middleName" class="form-control" />
                                  <!-- :class="{'is-invalid': v$.form.personal1.middleName.$error}"
                                  <div v-for="(personal1, index) in v$.form.personal1
                                    .middleName.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal1.$message">{{
                                      personal1.$message
                                    }}</span>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Home Address</label>
                            <textarea type="textarea" v-model="form.personal1.address" class="form-control"
                              rows="3"></textarea>
                            <!-- :class="{'is-invalid': v$.form.personal1.address.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .address.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Geo Code</label>
                            <input type="number" v-model="form.personal1.geoCode" class="form-control"  placeholder="Geo Code" :class="{'is-invalid': v$.form.personal1.geoCode.$error}"/>
                            <div v-for="(personal1, index) in v$.form.personal1
                              .geoCode.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="col-12 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Mailing Address
                              <sup>(if different from above)</sup></label>
                            <textarea type="textarea" v-model="form.personal1.mailingAddress" class="form-control"  rows="3" placeholder="Mailing Address..." :class="{'is-invalid': v$.form.personal1.mailingAddress.$error}"></textarea>
                            <div v-for="(personal1, index) in v$.form.personal1
                              .mailingAddress.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Address Line #1 </label>
                            <textarea type="text" v-model="form.personal1.address_line" class="form-control" rows="1"
                              :class="{
                                'is-invalid':
                                  v$.form.personal1.address_line.$error,
                              }"></textarea>

                            <div v-for="(personal1, index) in v$.form.personal1
                                .address_line.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Address Line #2 </label>
                            <textarea type="text" v-model="form.personal1.address_line_two" class="form-control"
                              rows="1"></textarea>
                            <!-- :class="{'is-invalid': v$.form.personal1.address_line_two.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .address_line_two.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">City</label>
                            <textarea type="text" v-model="form.personal1.city_name" class="form-control" rows="1"
                              :class="{
                                'is-invalid':
                                  v$.form.personal1.city_name.$error,
                              }"></textarea>

                            <div v-for="(personal1, index) in v$.form.personal1
                                .city_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">State / Prov</label>
                            <textarea type="text" v-model="form.personal1.state_name" class="form-control"
                              rows="1"></textarea>
                            <!-- :class="{'is-invalid': v$.form.personal1.state_name.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .state_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Zip Code</label>
                            <input type="number" v-model="form.personal1.zip_code" @input="validateZipCode"
                              class="form-control" rows="1" inputmode="numeric" />
                            <!-- :class="{'is-invalid': v$.form.personal1.zip_code.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .zip_code.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal1.country_name" :options="country_nameOpt" :class="{
                                  'is-invalid':
                                    v$.form.personal1.country_name.$error,
                                }" />
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .country_name.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <!-- <textarea type="text" v-model="form.personal1.country_name" class="form-control"  rows="1" :class="{'is-invalid': v$.form.personal1.country_name.$error}"></textarea>
                            
                            <div v-for="(personal1, index) in v$.form.personal1
                              .country_name.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Date of Birth</label>
                            <input type="date" v-model="form.personal1.dob" class="form-control" :class="{
                                'is-invalid': v$.form.personal1.dob.$error,
                              }" />
                            <label v-if="isInvalidDOB" style="color: red">Date of Birth cannot be greater than the
                              current
                              date.</label>
                            <label v-if="invalidDOBMinor" style="color: red">Date of birth must indicate a minor's
                              age.</label>
                            <label v-if="invalidDOBadult" style="color: red">Date of birth must indicate an adult's
                              age.</label>
                            <div v-for="(personal1, index) in v$.form.personal1.dob
                                .$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <h4 class="font-size-14">Gender</h4>
                          <div class="row">
                            <div class="col-auto mb-2">
                              <label for="maleGender" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid': v$.form.personal1.gender.$error,
                                }">
                                <input type="radio" v-model="form.personal1.gender" name="genderRadio"
                                  class="inputRadioCstm d-none" id="maleGender" value="male" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Male
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .gender.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="femaleGender" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid': v$.form.personal1.gender.$error,
                                }">
                                <input type="radio" v-model="form.personal1.gender" name="genderRadio"
                                  class="inputRadioCstm d-none" id="femaleGender" value="female" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Female
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .gender.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">No. of Dependants</label>
                            <input type="number" v-model="form.personal1.numOfDependents" class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal1.numOfDependents.$error,
                              }" inputmode="numeric" />
                            <div v-for="(personal1, index) in v$.form.personal1
                                .numOfDependents.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                              <!-- ------------------------------------------------------------------------------------ -->
                            </div>
                            <!-- <div class="">
                              <label>
                                <input type="file"  @change="onFileChange()"/>
                              </label>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Place of Birth</label>
                            <div class="single-select2-cstm">
                              <!-- <Select2
                                v-model="form.personal1.placeOfdob"
                                :options="nationalityOpt"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.placeOfdob.$error,
                                }"
                              /> -->
                              <textarea type="text" v-model="form.personal1.placeOfdob" class="form-control" rows="1"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.placeOfdob.$error,
                                }"></textarea>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .placeOfdob.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Nationality</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal1.nationality" :options="nationalityOpt" :class="{
                                  'is-invalid':
                                    v$.form.personal1.nationality.$error,
                                }" />
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .nationality.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <!-- <input type="text" v-model="form.personal1.nationality" class="form-control" :class="{'is-invalid': v$.form.personal1.nationality.$error}"/>
                            <div v-for="(personal1, index) in v$.form.personal1
                              .nationality.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <h4 class="font-size-14">Marital Status</h4>
                          <div class="row">
                            <div class="col-auto mb-2">
                              <label for="singleMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="single"
                                  name="materialRadio" class="inputRadioCstm d-none" id="singleMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Single
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="marriedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="married"
                                  name="materialRadio" class="inputRadioCstm d-none" id="marriedMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Married
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="divorcedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="divorced"
                                  name="materialRadio" class="inputRadioCstm d-none" id="divorcedMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Divorced
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="widowedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="widowed"
                                  name="materialRadio" class="inputRadioCstm d-none" id="widowedMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Widowed
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="col-auto mb-2">
                              <label for="separatedMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="separated"
                                  name="materialRadio" class="inputRadioCstm d-none" id="separatedMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Separated
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>

                            <div class="col-auto mb-2">
                              <label for="commonLawMat" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal1.maritalStatus.$error,
                                }">
                                <input type="radio" v-model="form.personal1.maritalStatus" value="common_low"
                                  name="materialRadio" class="inputRadioCstm d-none" id="commonLawMat" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Common Law
                                </h4>
                              </label>
                              <div v-for="(personal1, index) in v$.form.personal1
                                  .maritalStatus.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">National Identification No.</label>
                            <input type="text" v-model="form.personal1.nationalIdNum" class="form-control"
                              @input="validatenationalidentification" inputmode="numeric" />
                            <!-- <div v-for="(personal1, index) in v$.form.personal1
                              .nationalIdNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal1.nidIssueDate" class="form-control" />
                            <label class="font-size-4" style="color: red" v-if="isIssueDateInvalid">Issue Date should be
                              in Past.</label>
                            <!-- showMessageAfterClearingdid -->
                            <label class="font-size-4" style="color: red" v-if="isIssueDateFuture">Issue Date cannot be
                              a future date.</label>
                            <!-- <div v-for="(personal1, index) in v$.form.personal1
                              .nidIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                            <!-- <div v-if="isIssueDateInvalid" class="invalid-feedback">
                              
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal1.nidExpiry" class="form-control" />
                            <label class="font-size-4" style="color: red" v-if="isExpiryDateInvalid">Expiry Date should
                              be in Future.</label>
                            <!-- <div v-for="(personal1, index) in v$.form.personal1
                              .nidExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                            <div v-if="isExpiryDateInvalid" class="invalid-feedback">
                              Expiry Date should be after Issue Date.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal1.nationalIssuance" :options="nationalIssuanceOpt" />
                              <!-- <div v-for="(personal1, index) in v$.form.personal1
                                .nationalIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                }}</span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <div v-if="errorpartone" style="color: red">
                          Please fill in at least one of the three sections.
                        </div>
                        <div v-if="errorpart" style="color: red">
                          Please fill all fields of National Identification No.
                        </div>

                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Driver’s Permit No.</label>
                            <input type="text" v-model="form.personal1.drivingIdNum" class="form-control" maxlength="13"
                              @input="validateDrivingIdNum" />
                            <!-- :class="{'is-invalid': v$.form.personal1.drivingIdNum.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .drivingIdNum.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal1.didIssueDate" class="form-control" />
                            <label class="font-size-4" v-if="isdidIssueDateInvalid" style="color: red">Issue Date should
                              be in Past.</label>
                            <label class="font-size-4" style="color: red" v-if="isdidIssueDateFuture">Issue Date cannot
                              be a future date.</label>
                            <!-- :class="{'is-invalid': v$.form.personal1.didIssueDate.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .didIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal1.didExpiry" class="form-control" />
                            <label class="font-size-1" style="color: red" v-if="isdidExpiryDateInvalid">Expiry Date
                              should be in Future.</label>
                            <!-- :class="{'is-invalid': v$.form.personal1.didExpiry.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .didExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal1.driverIssuance" :options="nationalIssuanceOpt" />
                              <!-- :class="{'is-invalid': v$.form.personal1.driverIssuance.$error}"
                              <div v-for="(personal1, index) in v$.form.personal1
                                .driverIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                }}</span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <div v-if="errorparttwo" style="color: red">
                          If you fill in Driver’s Permit No., please fill in at
                          least one more field from National Identification No.
                          and Passport No.
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Passport No.</label>
                            <!-- <template v-if="form.personal1.drivingIdNum.trim() !== ''"> -->
                            <input type="text" v-model="form.personal1.passportNum" class="form-control"
                              @input="validatepassportnumber" />
                            <!-- <div v-for="(personal1, index) in v$.form.personal1
                                .passportNum.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                }}</span>
                              </div> -->
                            <!-- </template>
                          <template v-else>
                            <input type="text" v-model="form.personal1.passportNum"  class="form-control" @input="validatepassportnumber"/>
                          </template> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Issue Date</label>
                            <input type="date" v-model="form.personal1.passIssueDate" class="form-control" />
                            <label class="font-size-4" v-if="ispassIssueDateInvalid" style="color: red">Issue Date
                              should be in Past.</label>
                            <label class="font-size-4" style="color: red" v-if="ispassIssueDateFuture">Issue Date cannot
                              be a future date.</label>
                            <!-- :class="{'is-invalid': v$.form.personal1.passIssueDate.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .passIssueDate.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Expiry Date</label>
                            <input type="date" v-model="form.personal1.passExpiry" class="form-control" />
                            <label class="font-size-1" style="color: red" v-if="ispassExpiryDateInvalid">Expiry Date
                              should be in Future.</label>
                            <!-- :class="{'is-invalid': v$.form.personal1.passExpiry.$error}"
                            <div v-for="(personal1, index) in v$.form.personal1
                              .passExpiry.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                              }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Country of Issuance</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal1.passportIssuance" :options="nationalIssuanceOpt" />
                              <!-- :class="{'is-invalid': v$.form.personal1.passportIssuance.$error}"
                              <div v-for="(personal1, index) in v$.form.personal1
                                .passportIssuance.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                }}</span>
                              </div> -->
                            </div>
                          </div>
                        </div>

                        <template v-if="radioSelected1 === 'minor'">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Birth Certificate PIN No.
                                <sup>(for minors)</sup>
                              </label>
                              <input type="number" v-model="form.personal1.birthIdNum" class="form-control" />
                              <!-- :class="{'is-invalid': v$.form.personal1.birthIdNum.$error}"
                              <div v-for="(personal1, index) in v$.form.personal1
                                .birthIdNum.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal1.$message">{{
                                  personal1.$message
                                }}</span>
                              </div> -->
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Country of Issuance</label>
                              <div class="single-select2-cstm">
                                <Select2 v-model="form.personal1.birthIssuance" :options="nationalIssuanceOpt" />
                                <!-- :class="{'is-invalid': v$.form.personal1.birthIssuance.$error}"
                                <div v-for="(personal1, index) in v$.form.personal1
                                  .birthIssuance.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal1.$message">{{
                                    personal1.$message
                                  }}</span>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </template>

                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Area Code / Home Number</label><span>(Numbers only)</span>
                            <div class="d-flex">
                              <div class="single-select2-cstm" style="min-width: 96px">
                                <Select2 v-model="form.personal1.homePhoneNumcode" :options="countrynameIssuanceOpt"
                                  :class="{
                                    'is-invalid':
                                      v$.form.personal1.homePhoneNumcode.$error,
                                  }">
                                </Select2>

                                <div v-for="(personal1, index) in v$.form.personal1
                                    .homePhoneNumcode.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal1.$message">{{
                                    personal1.$message
                                    }}</span>
                                </div>
                              </div>

                              <div class="d-block">
                                <input type="number" placeholder="No dashes or spaces"
                                  v-model="form.personal1.homePhoneNum" class="form-control" inputmode="numeric" :class="{
                                    'is-invalid':
                                      v$.form.personal1.homePhoneNum.$error,
                                  }" @input="validatehomenumber" />
                                <label class="font-size-1" v-if="ispassIssuenumberInvalid" style="color: red">Atleast 7
                                  number required</label>
                                <div v-for="(personal1, index) in v$.form.personal1
                                    .homePhoneNum.$errors" :key="index" class="invalid-feedback">
                                  <span class="d-block" v-if="personal1.$message">{{ personal1.$message }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Area Code / Mobile No.</label><span>(Numbers only)</span>
                            <div class="d-flex">
                              <div class="single-select2-cstm" style="min-width: 96px">
                                <Select2 v-model="form.personal1.mobileNumcode" :options="countrynameIssuanceOpt">
                                </Select2>
                                <!-- :class="{ 'is-invalid': v$.form.personal1.mobileNumcode.$error,}"
                                  <div v-for="(personal1, index) in v$.form.personal1.mobileNumcode.$errors" :key="index" class="invalid-feedback" >
                                      <span v-if="personal1.$message">{{
                                        personal1.$message
                                      }}</span>
                                </div> -->
                              </div>
                              <div class="d-block">
                                <input type="number" v-model="form.personal1.mobileNum"
                                  placeholder="No dashes or spaces" class="form-control" @input="validatemobnumber" />
                                <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 7
                                  number required</label>

                                <!-- :class="{
                                  'is-invalid':
                                    v$.form.personal1.mobileNum.$error,
                                }"
                                <div
                                    v-for="(personal1, index) in v$.form.personal1
                                      .mobileNum.$errors"
                                    :key="index"
                                    class="invalid-feedback"
                                  >
                                  <span v-if="personal1.$message">{{
                                    personal1.$message
                                  }}</span>
                                </div> -->
                              </div>
                            </div>
                            <!-- <input type="tel" v-model="form.personal1.mobileNum" placeholder="No dashes or spaces" class="form-control" :class="{'is-invalid': v$.form.personal1.mobileNum.$error}" @input="validatemobnumber"/> -->
                          </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <div class="d-block">
                            <label class="font-size-14">Email Address</label>
                            <input type="email" v-model="form.personal1.email" class="form-control" :class="{
                                'is-invalid': v$.form.personal1.email.$error,
                              }" />
                            <div v-for="(personal1, index) in v$.form.personal1
                                .email.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal1.$message">{{
                                personal1.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uploadFileSection groupFormStyle">
                        <h2 class="formMainHeading">Upload Documents</h2>
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file">National Identification</label>
                              <input type="file" ref="fileInput1"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png,  "
                                @change="onFileChange1" class="form-control" id="my-file" />

                              <template v-if="preview[0]">
                                <div class="border bg-white p-2 mt-3">
                                  <h4 class="font-size-15 mb-3">
                                    National Identification Here:
                                  </h4>
                                  <img v-if="
                                      preview[0].type === 'pdf' ||
                                      preview[0].type === 'docx' ||
                                      preview[0].type === 'xlsx' ||
                                      preview[0].type === 'image/jpeg' ||
                                      preview[0].type === 'image/png'
                                    " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                    class="icon-file-upload" />
                                  <img v-else :src="preview[0].value" class="img-custom-preview" />
                                  <p class="mb-0">
                                    file name: {{ image[0].name }}
                                  </p>
                                  <p class="mb-0">
                                    size: {{ image[0].size / 1024 }}KB
                                  </p>
                                </div>
                              </template>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file1">Driver’s Permit</label>
                              <input type="file" ref="fileInput2"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png,"
                                @change="onFileChange2" class="form-control" id="my-file1" />

                              <template v-if="preview[1]">
                                <div class="border bg-white p-2 mt-3">
                                  <h4 class="font-size-15 mb-3">
                                    Driver’s Permit Here:
                                  </h4>
                                  <img v-if="
                                      preview[1].type === 'pdf' ||
                                      preview[1].type === 'docx' ||
                                      preview[1].type === 'xlsx' ||
                                      preview[1].type === 'image/jpeg' ||
                                      preview[1].type === 'image/png'
                                    " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                    class="icon-file-upload" />
                                  <img v-else :src="preview[1].value" class="img-custom-preview" />
                                  <p class="mb-0">
                                    file name: {{ image[1].name }}
                                  </p>
                                  <p class="mb-0">
                                    size: {{ image[1].size / 1024 }}KB
                                  </p>
                                </div>
                              </template>
                              <!-- <template v-if="preview[1]">
                                <div class="border bg-white p-2 mt-3">
                                  <h4 class="font-size-15 mb-3">
                                    Driver’s Permit Here:
                                  </h4>
                              
                                  <div v-if="!isPdf(preview[1].type)" class="alert alert-danger">
                                    The file must be in PDF format.
                                  </div>
                              
                                  <img v-if="isPdf(preview[1].type)" src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                    class="icon-file-upload" />
                              
                                  <img v-else :src="preview[1].value" class="img-custom-preview" />
                              
                                  <p class="mb-0">
                                    File name: {{ image[1].name }}
                                  </p>
                                  <p class="mb-0">
                                    Size: {{ image[1].size / 1024 }}KB
                                  </p>
                                </div>
                              </template> -->
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file2">Passport</label>
                              <input type="file" ref="fileInput3"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                @change="onFileChange3" class="form-control" id="my-file2" />
                              <template v-if="preview[2]">
                                <div class="border bg-white p-2 mt-3">
                                  <h4 class="font-size-15 mb-3">
                                    Passport Here:
                                  </h4>
                                  <img v-if="
                                      preview[2].type === 'pdf' ||
                                      preview[2].type === 'docx' ||
                                      preview[2].type === 'xlsx' ||
                                      preview[2].type === 'image/jpeg' ||
                                      preview[2].type === 'image/png'
                                    " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                    class="icon-file-upload" />
                                  <img v-else :src="preview[2].value" class="img-custom-preview" />
                                  <p class="mb-0">
                                    file name: {{ image[2].name }}
                                  </p>
                                  <p class="mb-0">
                                    size: {{ image[2].size / 1024 }}KB
                                  </p>
                                </div>
                              </template>
                            </div>
                          </div>
                          <br />
                          <hr />
                          <br />
                          <div class="col-lg-4 mb-3">
                            <div class="form-group">
                              <label for="my-file3">Utility Bill</label>
                              <input type="file" ref="fileInput5"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                @change="onFileChange4" class="form-control" id="my-file4" />
                            </div>
                          </div>

                          <div class="col-lg-4" v-if="radioSelected1 === 'minor'">
                            <div class="form-group">
                              <label for="my-file2">Birth Certificate</label>
                              <input type="file" ref="fileInput4"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                @change="onFileChange5" class="form-control" id="my-file2" />
                            </div>
                          </div>

                          <div class="col-lg-4">
                            <div class="form-group">
                              <label for="my-file2">Other's (Pay Slip Or Job Letter)</label>
                              <input type="file" ref="fileInput9"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                @change="onFileChange9" class="form-control" id="my-file9" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content class="tab_2" title="" :before-change="handleBeforeChange1">
                    <h2 class="titleStepForm">Employment Information</h2>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <h4 class="font-size-14">Employment Status</h4>
                        <div class="row">
                          <div class="col-auto mb-2">
                            <label for="permanentRd" class="mb-0 w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2"
                              :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="permanent"
                                class="inputRadioCstm d-none" id="permanentRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Permanent
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="temporaryRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="temporary"
                                class="inputRadioCstm d-none" id="temporaryRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Temporary
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="contractRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="contract"
                                class="inputRadioCstm d-none" id="contractRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Contract
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="slfEmplyRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="self_employed"
                                class="inputRadioCstm d-none" id="slfEmplyRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Self-Employed
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="unemployedRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="unemployed"
                                class="inputRadioCstm d-none" id="unemployedRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Unemployed
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="retiredRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.employStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.employStatus" value="retired"
                                class="inputRadioCstm d-none" id="retiredRd" name="empStat" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Retired
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .employStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <h4 class="font-size-14">Pay Frequency</h4>
                        <div class="row">
                          <div class="col-auto">
                            <label for="weekRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.frequencyStatus" value="weekly"
                                class="inputRadioCstm d-none" id="weekRd" name="pay_freq" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Weekly
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="fortnightRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.frequencyStatus" value="fortnightly"
                                class="inputRadioCstm d-none" id="fortnightRd" name="pay_freq" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Fortnightly
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="monthRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal2.frequencyStatus.$error,
                              }">
                              <input type="radio" v-model="form.personal2.frequencyStatus" value="monthly"
                                class="inputRadioCstm d-none" id="monthRd" name="pay_freq" /><span
                                class="radioCircleIcon"><i class="mdi mdi-circle-outline text-theme font-size-20"></i><i
                                  class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i></span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Monthly
                              </h4>
                            </label>
                            <div v-for="(personal2, index) in v$.form.personal2
                                .frequencyStatus.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="row">
                          <label class="font-size-14">Start Date Of Current Employment</label>
                          <div class="col-6 pe-1 single-select2-cstm">
                            <Select2 v-model="form.personal2.user_howlong" :options="selectedMonthOpt"
                              placeholder="Please Select Month" :class="{
                                'is-invalid':
                                  v$.form.personal2.user_howlong.$error,
                              }" />
                            <!-- <input type="month" v-model="form.personal2.user_howlong" class="form-control"
                                :class="{'is-invalid': v$.form.personal2.user_howlong.$error}"/>-->
                            <div v-for="(personal2, index) in v$.form.personal2
                                .user_howlong.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-6 ps-1 single-select2-cstm">
                            <Select2 v-model="form.personal2.user_howlong2" :options="selectedYearOpt"
                              placeholder="Please Select Year" :class="{
                                'is-invalid':
                                  v$.form.personal2.user_howlong2.$error,
                              }" />
                            <!-- <input type="number" min="1900" max="2099" v-model="selectedYear" class="date-own form-control" /> -->

                            <div v-for="(personal2, index) in v$.form.personal2
                                .user_howlong2.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 mb-3">
                        <h4 class="font-size-14">Average Monthly Income</h4>
                        <div class="row mb-12">
                          <div class="mb-2">
                            <div class="col-lg-12 single-select2-cstm">
                              <Select2 v-model="form.personal2.averageIncm" :options="averageIncmOpt" :class="{
                                  'is-invalid':
                                    v$.form.personal2.averageIncm.$error,
                                }" />
                              <div v-for="(personal2, index) in v$.form.personal2
                                  .averageIncm.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal2.$message">{{
                                  personal2.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Occupation</label>
                          <input type="text" v-model="form.personal2.user_occupation" class="form-control" :class="{
                              'is-invalid':
                                v$.form.personal2.user_occupation.$error,
                            }" />
                          <div v-for="(personal2, index) in v$.form.personal2
                              .user_occupation.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal2.$message">{{
                              personal2.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Employer</label>
                          <input type="text" v-model="form.personal2.employer" class="form-control" :class="{
                              'is-invalid': v$.form.personal2.employer.$error,
                            }" />
                          <div v-for="(personal2, index) in v$.form.personal2
                              .employer.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal2.$message">{{
                              personal2.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="d-block" v-if="form.personal2.employStatus === 'unemployed'">
                          <label class="font-size-14">Employer's Address</label>
                          <textarea type="textarea" v-model="form.personal2.user_employer"
                            class="form-control resize-none" rows="3"></textarea>
                        </div>
                        <div class="d-block" v-else>
                          <label class="font-size-14">Employer's Address</label>
                          <textarea type="textarea" v-model="form.personal2.user_employer"
                            class="form-control resize-none" rows="3" :class="{
                              'is-invalid':
                                v$.form.personal2.user_employer.$error,
                            }"></textarea>
                          <div v-for="(personal2, index) in v$.form.personal2
                              .user_employer.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal2.$message">{{
                              personal2.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6 mb-3" >
                        <div class="d-block" v-if="form.personal2.employStatus === 'unemployed'">
                          <label class="font-size-14">Work Phone No</label>
                          <input type="number" v-model="form.personal2.user_w_phone_no" class="form-control"
                            inputmode="numeric"/>
                        </div>
                        <div class="d-block" v-else>
                          <label class="font-size-14">Work Phone No</label>
                          <input type="number" v-model="form.personal2.user_w_phone_no" class="form-control"
                            :class="{'is-invalid': v$.form.personal2.user_w_phone_no.$error}" inputmode="numeric" @input="validatemobnumber2"/>
                            <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 9 number required</label>
                            <div v-for="(personal2, index) in v$.form.personal2
                              .user_w_phone_no.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal2.$message">{{
                                personal2.$message
                              }}</span> 
                            </div> 
                        </div>
                      </div> -->
                      <div class="col-lg-6 mb-3">
                        <div class="d-block" v-if="form.personal2.employStatus === 'unemployed'">
                          <label class="font-size-14">Work Phone No</label><span>(Numbers only)</span>
                          <div class="d-flex">
                            <div class="single-select2-cstm" style="min-width: 96px">
                              <Select2 v-model="form.personal2.user_w_phone_nocode" :options="countrynameIssuanceOpt">
                              </Select2>
                              <div :class="{
                                  'is-invalid':
                                    v$.form.personal2.user_w_phone_nocode
                                      .$error,
                                }" v-for="(personal2, index) in v$.form.personal2
                                  .user_w_phone_nocode.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal2.$message">{{
                                  personal2.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="d-block">
                              <input type="number" v-model="form.personal2.user_w_phone_no" class="form-control"
                                inputmode="numeric" />
                            </div>
                          </div>
                          <!-- <input type="tel" v-model="form.personal1.mobileNum" placeholder="No dashes or spaces" class="form-control" :class="{'is-invalid': v$.form.personal1.mobileNum.$error}" @input="validatemobnumber"/> -->
                        </div>
                        <div class="d-block" v-else>
                          <label class="font-size-14">Work Phone No</label><span>(Numbers only)</span>
                          <div class="d-flex">
                            <div class="single-select2-cstm" style="min-width: 96px">
                              <Select2 v-model="form.personal2.user_w_phone_nocode" :options="countrynameIssuanceOpt"
                                :class="{
                                  'is-invalid':
                                    v$.form.personal2.user_w_phone_nocode
                                      .$error,
                                }">
                              </Select2>
                              <div v-for="(personal2, index) in v$.form.personal2
                                  .user_w_phone_nocode.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal2.$message">{{
                                  personal2.$message
                                  }}</span>
                              </div>
                            </div>
                            <div class="d-block col-lg-8">
                              <input type="number" v-model="form.personal2.user_w_phone_no"
                                placeholder="No dashes or spaces" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal2.user_w_phone_no.$error,
                                }" @input="validatemobnumber2" inputmode="numeric" />
                              <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 7 number
                                required</label>

                              <div v-for="(personal2, index) in v$.form.personal2
                                  .user_w_phone_no.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal2.$message">{{
                                  personal2.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <!-- <input type="tel" v-model="form.personal1.mobileNum" placeholder="No dashes or spaces" class="form-control" :class="{'is-invalid': v$.form.personal1.mobileNum.$error}" @input="validatemobnumber"/> -->
                        </div>
                      </div>
                      <!-- <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14"></label>
                          
                            
                        </div>
                      </div> -->
                      <div class="col-lg-6 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">Ext. <sup>(if any)</sup></label>
                          <input type="number" inputmode="numeric" v-model="form.personal2.user_phone_no"
                            class="form-control" @input="validatemobnumber3" />
                          <!-- <label class="font-size-1" v-if="ispassnumberInvalid2" style="color: red">Atleast 9 number required</label> -->
                          <!-- :class="{'is-invalid': v$.form.personal2.user_phone_no.$error}"
                            <div v-for="(personal2, index) in v$.form.personal2
                              .user_phone_no.$errors" :key="index" class="invalid-feedback"> 
                              <span v-if="personal2.$message">{{
                                personal2.$message
                              }}</span>
                            </div> -->
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">School
                            <sup>(if applicant is a student)</sup></label>
                          <textarea type="textarea" v-model="form.personal2.user_isstudent"
                            class="form-control resize-none" rows="3"></textarea>
                          <!-- :class="{'is-invalid': v$.form.personal2.user_isstudent.$error}"
                            <div v-for="(personal2, index) in v$.form.personal2
                              .user_isstudent.$errors" :key="index" class="invalid-feedback"> 
                              <span v-if="personal2.$message">{{
                                personal2.$message
                              }}</span> 
                            </div>  -->
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content class="tab_3" title="" :before-change="handleBeforeChange2">
                    <h2 class="titleStepForm">Account Activity</h2>
                    <div class="row">
                      <!-- <div class="col-12 mb-3">
                        <div class="d-block">
                          <label class="font-size-14">How will account be funded</label>
                          <textarea type="textarea" v-model="form.personal3.accFunded" class="form-control resize-none"
                            rows="3" :class="{'is-invalid': v$.form.personal3.accFunded.$error}"></textarea>
                            <div v-for="(personal3, index) in v$.form.personal3
                              .accFunded.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal3.$message">{{
                                personal3.$message
                              }}</span>
                            </div>
                        </div>
                      </div> -->
                      <div class="col-lg-6">
                        <label class="font-size-14">How will account be funded</label>
                        <div class="single-select2-cstm">
                          <Select2 v-model="form.personal3.accFunded" :class="{
                              'is-invalid': v$.form.personal3.accFunded.$error,
                            }" :options="fundedoption" />
                          <div v-for="(personal3, index) in v$.form.personal3
                              .accFunded.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal3.$message">{{
                              personal3.$message
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 mb-3">
                        <h4 class="font-size-14">
                          Are you a member of any other Credit Union / Financial
                          Institution?
                        </h4>
                        <p class="subTitleForm">
                          (Financial Institution e.g. Bank, UTC, Securities
                          Company)
                        </p>
                        <div class="row gx-3">
                          <div class="col-auto">
                            <label for="yesAcc" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                                'is-invalid':
                                  v$.form.personal3.accountActi.$error,
                              }">
                              <input type="radio" v-model="form.personal3.accountActi" :value="true"
                                name="accountActivity" class="inputRadioCstm d-none" id="yesAcc" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal3, index) in v$.form.personal3
                                .accountActi.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal3.$message">{{
                                personal3.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noAcc" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                                'is-invalid':
                                  v$.form.personal3.accountActi.$error,
                              }">
                              <input type="radio" v-model="form.personal3.accountActi" :value="false"
                                name="accountActivity" class="inputRadioCstm d-none" id="noAcc" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal3, index) in v$.form.personal3
                                .accountActi.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal3.$message">{{
                                personal3.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="textareaShow" v-if="form.personal3.accountActi == true">
                          <textarea type="textarea" v-model="form.personal3.accountActiDesc"
                            class="form-control resize-none" rows="4"></textarea>
                        </div>
                        <!-- :class="{'is-invalid': v$.form.personal3.accountActiDesc.$error}"
                          <div v-for="(personal3, index) in v$.form.personal3
                            .accountActiDesc.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal3.$message">{{
                              personal3.$message
                            }}</span>
                          </div> -->
                      </div>

                      <div class="col-lg-12" v-if="form.personal3.accFunded === 'Other'">
                        <div class="form-group">
                          <label class="font-size-14">Enter Details </label>
                          <input type="text" v-model="form.personal3.other_details" class="form-control" />
                          <!-- :class="{'is-invalid': v$.form.personal3.other_details.$error}"
                              <div v-for="(personal3, index) in v$.form.personal3
                                .other_details.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal3.$message">{{
                                  personal3.$message
                                }}</span>
                              </div> -->
                        </div>
                      </div>

                      <!-- <div class="col-lg-6">
                        <label class="font-size-14"
                          >Include source of income
                        </label>
                        <div class="single-select2-cstm">
                          <Select2
                            v-model="form.personal3.socuceofincome"
                            :options="Incomeoption"
                            :class="{
                              'is-invalid':
                                v$.form.personal3.socuceofincome.$error,
                            }"
                          />
                          <div
                            v-for="(personal3, index) in v$.form.personal3
                              .socuceofincome.$errors"
                            :key="index"
                            class="invalid-feedback"
                          >
                            <span v-if="personal3.$message">{{
                              personal3.$message
                            }}</span>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-lg-6">
                        <label class="font-size-14">Account Funding Details</label>
                        <div class="single-select2-cstm">
                          <input type="text" v-model="form.personal3.areaofdetails" class="form-control" :class="{
                              'is-invalid':
                                v$.form.personal3.areaofdetails.$error,
                            }" />
                          <div v-for="(personal3, index) in v$.form.personal3
                              .areaofdetails.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal3.$message">{{
                              personal3.$message
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <br />
                        <h3 class="mb-2 font-size-18">
                          Anticipated level of activity
                        </h3>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="font-size-14">Number of transactions</label>
                              <input type="number" inputmode="numeric" v-model="form.personal3.numTrans"
                                @input="validatenumbrtrans" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal3.numTrans.$error,
                                }" />
                              <div v-for="(personal3, index) in v$.form.personal3
                                  .numTrans.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal3.$message">{{
                                  personal3.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="font-size-14">Volume of Transactions (TT$)</label>
                              <input type="number" inputmode="numeric" v-model="form.personal3.volTrans"
                                @input="validatevlumtrans" class="form-control" :class="{
                                  'is-invalid':
                                    v$.form.personal3.volTrans.$error,
                                }" />
                              <div v-for="(personal3, index) in v$.form.personal3
                                  .volTrans.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal3.$message">{{
                                  personal3.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content class="tab_4" title="" :before-change="handleBeforeChange3"
                    v-if="radioSelected1 !== 'adult'">
                    <h2 class="titleStepForm">Parent/Guardian information</h2>
                    <div class="row">
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Parent/ Legal Guardian: Name
                          </label>
                          <input type="text" v-model="form.personal4.parentName" class="form-control"
                            :disabled="radioSelected1 === 'adult'" :class="{
                              'is-invalid': v$.form.personal4.parentName.$error,
                            }" />
                          <div v-for="(personal4, index) in v$.form.personal4
                              .parentName.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal4.$message">{{
                              personal4.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Identification No. (ID/ DP/ PP)
                          </label>
                          <input type="text" v-model="form.personal4.parentId" class="form-control"
                            :disabled="radioSelected1 === 'adult'" :class="{
                              'is-invalid': v$.form.personal4.parentId.$error,
                            }" />
                          <div v-for="(personal4, index) in v$.form.personal4
                              .parentId.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal4.$message">{{
                              personal4.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Trustee:
                            <sup>(person authorised to act on behalf of
                              minor)</sup>
                          </label>
                          <input type="text" v-model="form.personal4.trusteeName" class="form-control"
                            :disabled="radioSelected1 === 'adult'" :class="{
                              'is-invalid':
                                v$.form.personal4.trusteeName.$error,
                            }" />
                          <div v-for="(personal4, index) in v$.form.personal4
                              .trusteeName.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal4.$message">{{
                              personal4.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <label class="font-size-14">Identification No. (ID/ DP/ PP)
                          </label>
                          <input type="number" v-model="form.personal4.trusteeId" class="form-control"
                            :disabled="radioSelected1 === 'adult'" :class="{
                              'is-invalid': v$.form.personal4.trusteeId.$error,
                            }" />
                          <div v-for="(personal4, index) in v$.form.personal4
                              .trusteeId.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal4.$message">{{
                              personal4.$message
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="groupFormStyle mt-4">
                      <div class="">
                        <h3 class="font-size-18">Statement of Declaration</h3>
                        <p>FOR PERSON WHO IS NOT PARENT/ GUARDIAN/ TRUSTEE</p>
                      </div>
                      <div class="">
                        <label for="declareId" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                          <input type="checkbox" v-model="form.personal4.declaration" name="declareNme"
                            :disabled="radioSelected1 === 'adult'" class="inputRadioCstm d-none" id="declareId" :class="{
                              'is-invalid':
                                v$.form.personal4.declaration.$error,
                            }" />
                          <div v-for="(personal4, index) in v$.form.personal4
                              .declaration.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal4.$message">{{
                              personal4.$message
                              }}</span>
                          </div>
                          <span class="radioCircleIcon">
                            <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                            <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                          </span>
                          <div class="d-flex">
                            I ,
                            <input type="text" v-model="form.personal4.advisor" class="form-control mx-1 p-0 w-50"
                              style="
                                background-color: #fffbf8;
                                border: none;
                                border-bottom: 1px solid black;
                                outline: none;
                              " />
                            declare as follows:
                          </div>
                        </label>
                        <ol class="listOlStyle">
                          <li>
                            That I of my own volition opened an account in the
                            name of
                            <strong> {{ form.personal9.appliname }} </strong>
                            who is a minor
                          </li>
                          <li>
                            That I am neither parent nor legal guardian of this
                            minor
                          </li>
                          <li>
                            That I am aware that I will not have access to any
                            funds of this minor’s account unless authorised by
                            said minor’s parent or legal guardian.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </tab-content>
                  <!-- <template v-if="checkFeildInput2 === true"> -->
                  <tab-content class="tab_5" title="" :before-change="handleBeforeChange4">
                    <h2 class="titleStepForm mb-2">
                      Politically Exposed Persons <sup>(PEPs)</sup>
                    </h2>
                    <p class="subTitleForm text-center">
                      Individuals who are or have been entrusted with prominent
                      functions by an international/ local organization such as:
                    </p>
                    <div class="formContentPoli mb-3">
                      <h3 class="font-size-22 mb-4 mt-5 text-center">
                        Please tick the one(s) that apply
                      </h3>
                      <p>
                        A <strong>“politically exposed person”</strong> (PEP)
                        means a person who is or was entrusted with a prominent
                        function by an international/ local organization or
                        important political functions such as:
                      </p>
                      <h5>International Organization:</h5>
                      <p>
                        Members of senior management such as directors and
                        members of the board or equivalent function. United
                        Nations and affiliated international organizations,
                        Organization of America States, Inter-American
                        Development Bank, International Labour Organization, and
                        Caribbean Financial Action Task Force.
                      </p>
                      <h5>Government:</h5>
                      <p>
                        Head of State, senior politician, senior government
                        official, judicial or military officials, and senior
                        executives of State owned corporations and important
                        political party officials. Senior government officials-
                        e.g. Permanent Secretary, Accounting Officer, Chief
                        Technical Officer, High Commissioner Senior executives
                        of state corporations e.g. Board members of all
                        Statutory Bodies. Senior political party officials e.g.
                        Chairman, Deputy Chairman, Secretary and Treasurer,
                        Judicial Official e.g. Judges, magistrates, Military
                        Officials e.g. a Lieutenant Colonel or higher rank.
                      </p>
                      <p>
                        An immediate family member or any individual publicly
                        known or actually known to the relevant financial
                        institution to be associated with a PEP.
                      </p>
                      <p>Are you or have you ever been:</p>
                    </div>
                    <div class="groupFormStyle">
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (a) Entrusted with a prominent function referred to
                          above
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesEntrust" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.entrustedRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.entrustedRadio" value="True"
                                class="inputRadioCstm d-none" id="yesEntrust" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .entrustedRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noEntrust" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.entrustedRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.entrustedRadio" value="False"
                                class="inputRadioCstm d-none" id="noEntrust" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .entrustedRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (b) an immediate family member of a person referred to
                          above (a) such as the spouse, parent, siblings,
                          children and children of the spouse of that person;
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesMember" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.memberOfRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.memberOfRadio" value="True"
                                class="inputRadioCstm d-none" id="yesMember" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .memberOfRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noMember" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.memberOfRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.memberOfRadio" value="False"
                                class="inputRadioCstm d-none" id="noMember" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .memberOfRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <h3 class="font-size-15">
                          (C) a close personal or professional associate of the
                          persons referred to above (a) or (b)
                        </h3>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesReferred" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.referredRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.referredRadio" value="True"
                                class="inputRadioCstm d-none" id="yesReferred" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .referredRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noReferred" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal5.referredRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal5.referredRadio" value="False"
                                class="inputRadioCstm d-none" id="noReferred" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal5, index) in v$.form.personal5
                                .referredRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal5.$message">{{
                                personal5.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="border-top pt-3">
                        <h4 class="text-center font-size-14 mb-0">
                          If you have answered “yes” to any of the questions
                          above, Enhanced Due Diligence Form to be completed
                        </h4>
                      </div>
                    </div>
                  </tab-content>
                  <!-- </template>
                  <template v-if="checkFeildInput1 === true"> -->
                  <tab-content class="tab_6" title="" :before-change="handleBeforeChange5">
                    <h2 class="titleStepForm">
                      Foreign Account Tax Compliance ACT <sup>(FATCA)</sup>
                    </h2>
                    <div class="">
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you a citizen of any country other than Trinidad
                          and Tobago?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          If yes, copies of relevant passport(s) to be provided.
                        </p>
                        <div>
                          <!-- Tobago Radio Buttons -->
                          <div class="row">
                            <!-- Yes Radio Button -->
                            <div class="col-auto">
                              <label for="yesTabago" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                  'is-invalid':
                                    v$.form.personal6.tobagoRadio.$error,
                                }">
                                <input type="radio" v-model="form.personal6.tobagoRadio" value="True"
                                  class="inputRadioCstm d-none" id="yesTabago" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  Yes
                                </h4>
                              </label>
                              <div v-for="(personal6, index) in v$.form.personal6
                                  .tobagoRadio.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal6.$message">{{
                                  personal6.$message
                                  }}</span>
                              </div>
                            </div>

                            <!-- No Radio Button -->
                            <div class="col-auto">
                              <label for="noTabago" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                  'is-invalid':
                                    v$.form.personal6.tobagoRadio.$error,
                                }">
                                <input type="radio" v-model="form.personal6.tobagoRadio" value="False"
                                  class="inputRadioCstm d-none" id="noTabago" />
                                <span class="radioCircleIcon">
                                  <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                  <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                </span>
                                <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                  No
                                </h4>
                              </label>
                              <div v-for="(personal6, index) in v$.form.personal6
                                  .tobagoRadio.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal6.$message">{{
                                  personal6.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- File Field (shown when 'Yes' is selected) -->
                        <div class="col-auto textareaShow" v-if="form.personal6.tobagoRadio === 'True'">
                          <input type="file"
                            accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                            @change="previewImage($event, '4')" class="form-control" id="my-file" />
                          <template v-if="preview[4]">
                            <div class="border bg-white p-2 mt-3">
                              <h4 class="font-size-15 mb-3">Bill Here:</h4>
                              <img v-if="
                                  preview[4].type === 'pdf' ||
                                  preview[4].type === 'docx' ||
                                  preview[4].type === 'xlsx'
                                " src="@/assets/images/ECU/icon-file.png" alt="icon-image" class="icon-file-upload" />
                              <img v-else :src="preview[4].value" class="img-custom-preview" />
                              <p class="mb-0">file name: {{ image[4].name }}</p>
                              <p class="mb-0">
                                size: {{ image[4].size / 1024 }}KB
                              </p>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you a US Citizen, Resident or Green Card Holder?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN , Document showing Immigration Status.
                        </p>
                        <div class="row mt-2 align-items-center">
                          <div class="col-md-4">
                            <div class="d-block">
                              <label class="font-size-14">SSN No</label>
                              <template v-if="
                                  form.personal6.citizenRadio === 'True' ||
                                  form.personal6.citizenRadio === ''
                                ">
                                <input type="number" v-model="form.personal6.ssn_no" class="form-control"
                                  @input="validateSSNNO" :class="{
                                    'is-invalid':
                                      v$.form.personal6.ssn_no.$error,
                                  }" :required="isSSNRequired" />
                                <div v-for="(personal6, index) in v$.form.personal6
                                    .ssn_no.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal6.$message">{{
                                    personal6.$message
                                    }}</span>
                                </div>
                              </template>
                              <template v-else-if="
                                  form.personal6.citizenRadio === 'False'
                                ">
                                <input type="number" v-model="form.personal6.ssn_no" class="form-control" />
                              </template>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="yesCitizen" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.citizenRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.citizenRadio" value="True"
                                class="inputRadioCstm d-none" id="yesCitizen" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .citizenRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noCitizen" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.citizenRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.citizenRadio" value="False"
                                class="inputRadioCstm d-none" id="noCitizen" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .citizenRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- File Field (shown when 'Yes' is selected) -->
                        <div class="col-auto textareaShow" v-if="form.personal6.citizenRadio === 'True'">
                          <input type="file"
                            accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                            @change="previewImage($event, '5')" class="form-control" id="my-file" />
                          <template v-if="preview[5]">
                            <div class="border bg-white p-2 mt-3">
                              <h4 class="font-size-15 mb-3">Bill Here:</h4>
                              <img v-if="
                                  preview[5].type === 'pdf' ||
                                  preview[5].type === 'docx' ||
                                  preview[5].type === 'xlsx'
                                " src="@/assets/images/ECU/icon-file.png" alt="icon-image" class="icon-file-upload" />
                              <img v-else :src="preview[5].value" class="img-custom-preview" />
                              <p class="mb-0">file name: {{ image[5].name }}</p>
                              <p class="mb-0">
                                size: {{ image[5].size / 1024 }}KB
                              </p>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">Do you have a US address?</h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN
                        </p>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesAddress" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.addressRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.addressRadio" value="True"
                                class="inputRadioCstm d-none" id="yesAddress" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .addressRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noAddress" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.addressRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.addressRadio" value="False"
                                class="inputRadioCstm d-none" id="noAddress" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .addressRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- File Field (shown when 'Yes' is selected) -->
                        <div class="col-auto textareaShow" v-if="form.personal6.addressRadio === 'True'">
                          <input type="file"
                            accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                            @change="previewImage($event, '6')" class="form-control" id="my-file" />
                          <template v-if="preview[6]">
                            <div class="border bg-white p-2 mt-3">
                              <h4 class="font-size-15 mb-3">Bill Here:</h4>
                              <img v-if="
                                  preview[6].type === 'pdf' ||
                                  preview[6].type === 'docx' ||
                                  preview[6].type === 'xlsx'
                                " src="@/assets/images/ECU/icon-file.png" alt="icon-image" class="icon-file-upload" />
                              <img v-else :src="preview[6].value" class="img-custom-preview" />
                              <p class="mb-0">file name: {{ image[6].name }}</p>
                              <p class="mb-0">
                                size: {{ image[6].size / 1024 }}KB
                              </p>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="mb-3 border-bottom pb-3">
                        <h3 class="font-size-16">
                          Are you giving instructions for the transfer of
                          dividends/ other income to a US Account?
                        </h3>
                        <p class="mb-0">
                          <strong class="font-size-13 font-weight-500 text-warning">REQUIREMENTS:</strong>
                          W-9 or W-8BEN
                        </p>
                        <div class="row">
                          <div class="col-auto">
                            <label for="yesIncomeUs" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.incomeUsRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.incomeUsRadio" value="True"
                                class="inputRadioCstm d-none" id="yesIncomeUs" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                Yes
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .incomeUsRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <label for="noIncomeUs" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2" :class="{
                                'is-invalid':
                                  v$.form.personal6.incomeUsRadio.$error,
                              }">
                              <input type="radio" v-model="form.personal6.incomeUsRadio" value="False"
                                class="inputRadioCstm d-none" id="noIncomeUs" />
                              <span class="radioCircleIcon">
                                <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                              </span>
                              <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                No
                              </h4>
                            </label>
                            <div v-for="(personal6, index) in v$.form.personal6
                                .incomeUsRadio.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal6.$message">{{
                                personal6.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- File Field (shown when 'Yes' is selected) -->
                        <div class="col-auto textareaShow" v-if="form.personal6.incomeUsRadio === 'True'">
                          <input type="file"
                            accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                            @change="previewImage($event, '7')" class="form-control" id="my-file" />
                          <template v-if="preview[7]">
                            <div class="border bg-white p-2 mt-3">
                              <h4 class="font-size-15 mb-3">Bill Here:</h4>
                              <img v-if="
                                  preview[7].type === 'pdf' ||
                                  preview[7].type === 'docx' ||
                                  preview[7].type === 'xlsx'
                                " src="@/assets/images/ECU/icon-file.png" alt="icon-image" class="icon-file-upload" />
                              <img v-else :src="preview[7].value" class="img-custom-preview" />
                              <p class="mb-0">file name: {{ image[7].name }}</p>
                              <p class="mb-0">
                                size: {{ image[7].size / 1024 }}KB
                              </p>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="pt-3">
                        <h4 class="text-center font-size-14 mb-0">
                          If you have answered “yes” to any of the questions
                          above, FATCA Documentation to be completed
                          <a href="https://www.irs.gov/businesses/corporations/fatca-related-forms"
                            target="_blank">Click Here.</a>
                        </h4>
                      </div>
                    </div>
                  </tab-content>
                  <!-- </template> -->
                  <!-- <tab-content class="tab_7" title="" :before-change="handleBeforeChange6">
                    <h2 class="titleStepForm">Account/Fees</h2>
                    <div class="">
                      <table class="table table-bordered table-custom-card">
                        <thead>
                          <tr>
                            <th>A/C Number</th>
                            <th>A/C Type</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input type="number" v-model="form.personal7.account_one" class="form-control" :class="{'is-invalid': v$.form.personal7.account_one.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.account_one.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                            </td>
                            <td>Shares</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal7.shares_amount" class="form-control" :class="{'is-invalid': v$.form.personal7.shares_amount.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.shares_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="number" v-model="form.personal7.account_two" class="form-control" :class="{'is-invalid': v$.form.personal7.account_two.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.account_two.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                            </td>
                            <td>Special Shares</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal7.special_shares_amount" class="form-control" :class="{'is-invalid': v$.form.personal7.special_shares_amount.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.special_shares_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="number" v-model="form.personal7.account_three" class="form-control" :class="{'is-invalid': v$.form.personal7.account_three.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.account_three.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                            </td>
                            <td>Deposit A/C</td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal7.deposit_ac_amount" class="form-control" :class="{'is-invalid': v$.form.personal7.deposit_ac_amount.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.deposit_ac_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                                <input type="number" v-model="fessacct" class="form-control"> 
                            </td>
                            <td>
                              <strong class="font-weight-500">Reg. Fee</strong>
                            </td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal7.reg_fee_amount" class="form-control" :class="{'is-invalid': v$.form.personal7.reg_fee_amount.$error}"/>
                                <div v-for="(personal7, index) in v$.form.personal7.reg_fee_amount.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal7.$message">{{
                                    personal7.$message}}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                                <input type="number" class="form-control"> 
                            </td>
                            <td>
                              <strong class="font-weight-500">Total</strong>
                            </td>
                            <td>
                              <div class="input-group">
                                <div class="input-group-text">$</div>
                                <input type="number" v-model="form.personal7.total_amount" class="form-control" :class="{'is-invalid': v$.form.personal7.total_amount.$error}"/>
                                  <div v-for="(personal7, index) in v$.form.personal7.total_amount.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal7.$message">{{
                                      personal7.$message}}
                                    </span>
                                  </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content> -->
                  <tab-content class="tab_8" title="" :before-change="handleBeforeChange7">
                    <h2 class="titleStepForm">
                      Appointment of Nominee <sup>(Beneficiary)</sup>
                    </h2>
                    <div class="">
                      <p>
                        In the event of my death I,
                        <strong>{{ form.personal9.appliname }} </strong> hereby
                        nominate the following person(s) to receive any monies
                        accruing to me in the Society:
                      </p>
                      <div class="groupFeildsNom border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Name</label>
                            <input type="text" v-model="form.personal8.appointnominee_name1" class="form-control"
                              :class="{
                                'is-invalid':
                                  v$.form.personal8.appointnominee_name1.$error,
                              }" />
                            <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_name1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal8.$message">{{
                                personal8.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-8 mb-3">
                            <label class="font-size-14"> Email Address</label>
                            <input type="email" v-model="form.personal8.appointnominee_email1" maxlength="100"
                              class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal8.appointnominee_email1
                                    .$error,
                              }" />
                            <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_email1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal8.$message">{{
                                personal8.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="d-block">
                            <label class="font-size-14">Phone Number</label><span>(Numbers only)</span>
                            <div class="d-flex">
                              <div class="single-select2-cstm" style="min-width: 96px">
                                <Select2 v-model="
                                    form.personal8.appointnominee_pno1code
                                  " :options="countrynameIssuanceOpt" :class="{
                                    'is-invalid':
                                      v$.form.personal8.appointnominee_pno1code
                                        .$error,
                                  }">
                                </Select2>
                                <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_pno1code.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal8.$message">{{
                                    personal8.$message
                                    }}</span>
                                </div>
                              </div>

                              <div class="d-block">
                                <input type="number" placeholder="No dashes or spaces"
                                  v-model="form.personal8.appointnominee_pno1" class="form-control" inputmode="numeric"
                                  :class="{
                                    'is-invalid':
                                      v$.form.personal8.appointnominee_pno1
                                        .$error,
                                  }" @input="validatemobnumber5" />
                                <label class="font-size-1" v-if="ispassnumberInvalid5" style="color: red">Atleast 7
                                  number required</label>
                                <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_pno1.$errors" :key="index" class="invalid-feedback">
                                  <span class="d-block" v-if="personal8.$message">{{ personal8.$message }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Phone Number</label>
                            <input type="number" inputmode="numeric" v-model="form.personal8.appointnominee_pno1"
                                    class="form-control" :class="{'is-invalid': v$.form.personal8.appointnominee_pno1.$error}" @input="validatemobnumber5"/>
                                <label class="font-size-1" v-if="ispassnumberInvalid5" style="color: red">Atleast 9 number required</label>
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_pno1.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div>
                          </div> -->
                          <br>
                          <br>
                          <br><br>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Rel. to Member</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal8.appointnominee_rel1" :options="relationNameOpt" :class="{
                                  'is-invalid':
                                    v$.form.personal8.appointnominee_rel1
                                      .$error,
                                }" />
                              <div v-for="(personal8, index) in v$.form.personal8
                                  .appointnominee_rel1.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3"
                            v-if="form.personal8.appointnominee_rel1 === 'Other family member'">
                            <label class="font-size-14">
                              Specify The Relation</label>
                            <input type="textarea" v-model="form.personal8.appointnominee_otherel"
                              class="form-control" />
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Date of Birth</label>
                            <input type="date" v-model="form.personal8.appointnominee_dob1" maxlength="10"
                              class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal8.appointnominee_dob1.$error,
                              }" />
                            <label v-if="isInvalidDOB1" style="color: red">Date of Birth cannot be greater than the
                              current
                              date.</label>
                            <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_rel1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal8.$message">{{
                                personal8.$message
                                }}</span>
                            </div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <label class="font-size-14">Address</label>
                            <input type="textarea" v-model="form.personal8.appointnominee_address1" maxlength="210"
                              class="form-control" :class="{
                                'is-invalid':
                                  v$.form.personal8.appointnominee_address1
                                    .$error,
                              }" />
                            <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_address1.$errors" :key="index" class="invalid-feedback">
                              <span v-if="personal8.$message">{{
                                personal8.$message
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="groupFeildsNom border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Name </label>
                            <input type="text" v-model="form.personal8.appointnominee_name2" class="form-control" />
                            <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_name2.$error}"
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_name2.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div> -->
                          </div>
                          <div class="col-lg-8 mb-3">
                            <label class="font-size-14"> Email Address</label>
                            <input type="email" v-model="form.personal8.appointnominee_email2" maxlength="100"
                              class="form-control" />
                            <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_email2.$error}"
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_email2.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div> -->
                          </div>
                          <!-- <div class="col-lg-4 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Phone Number</label><span>(Numbers only)</span>
                              <div class="d-flex">
                                <div class="single-select2-cstm" style="min-width:96px;">
                                  <Select2 v-model="form.personal8.appointnominee_pno2code"  :options="countrynameIssuanceOpt" :class="{'is-invalid': v$.form.personal8.appointnominee_pno2code.$error}">
                                  </Select2>
                                  <div v-for="(personal8, index) in v$.form.personal8.appointnominee_pno2code.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div>
                                </div>
                                  
                                <div class="d-block">
                                  <input type="number" placeholder="No dashes or spaces" v-model="form.personal8.appointnominee_pno2" class="form-control" inputmode="numeric" @input="validatemobnumber4" :class="{'is-invalid': v$.form.personal8.appointnominee_pno2.$error}"/>
                                  
                                  <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 9 number required</label>
                                  <div v-for="(personal8, index) in v$.form.personal8.appointnominee_pno2.$errors" :key="index" class="invalid-feedback">
                                    <span class="d-block" v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Phone Number</label>
                            <input type="number" inputmode="numeric" v-model="form.personal8.appointnominee_pno2"
                              class="form-control" @input="validatemobnumber4" />
                            <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 7 number
                              required</label>
                            <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_pno2.$error}"
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_pno2.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div> -->
                          </div>
                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Rel. to Member</label>
                            <div class="single-select2-cstm">
                              <Select2 v-model="form.personal8.appointnominee_rel2" :options="relationNameOpt" />
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3" v-if="
                              form.personal8.appointnominee_rel2 ===
                              'Other family member'
                            ">
                            <label class="font-size-14">
                              Specify The Relation</label>
                            <input type="textarea" v-model="form.personal8.appointnominee_othere2"
                              class="form-control" />
                          </div>

                          <div class="col-lg-4 mb-3">
                            <label class="font-size-14"> Date of Birth</label>
                            <input type="date" v-model="form.personal8.appointnominee_dob2" maxlength="10"
                              class="form-control" />
                            <label v-if="isInvalidDOB2" style="color: red">Date of Birth cannot be greater than the
                              current
                              date.</label>
                            <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_dob2.$error}"
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_dob2.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div> -->
                          </div>
                          <div class="col-lg-12 mb-3">
                            <label class="font-size-14"> Address</label>
                            <input type="textarea" v-model="form.personal8.appointnominee_address2" maxlength="210"
                              class="form-control" />
                            <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_address2.$error}"
                              <div v-for="(personal8, index) in v$.form.personal8
                                .appointnominee_address2.$errors" :key="index" class="invalid-feedback">
                                <span v-if="personal8.$message">{{
                                  personal8.$message
                                }}</span>
                              </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <p>
                          I reserve the right to change or terminate the
                          designated beneficiary/ies at any time. I further
                          agree that any designation, termination or change of
                          beneficiary shall be binding upon the Credit Union
                          only if filed with the Credit Union prior to my death.
                          In accordance with the Co-operative Societies Act Chap
                          81:03 , as outlined in
                          <strong>ECU’s Bye Laws #10,</strong> a duly named
                          nominee of a deceased member of the Society is
                          entitled to the sum not exceeding fifty thousand dollars ($50,000.00) of the unencumbered
                          money due to the death of the said member of the Society. All other
                          monies due to the deceased member shall fall into his
                          estate and be subject in all respects to the laws
                          relating to inheritance including the requirement to
                          pay estate duty.
                        </p>
                        <div class="minorDivShow" v-if="radioSelected1 === 'minor'">
                          <h6 class="font-size-15">
                            Complete this Section only if Nominee is a minor
                          </h6>
                          <label for="hereByRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0">
                            <!-- :class="{'is-invalid': v$.form.personal8.hereRadioType.$error}" -->
                            <input type="checkbox" :checked="
                                radioSelected1 === 'minor' ? 'true' : 'false'
                              " v-model="form.personal8.hereRadioType" class="inputRadioCstm d-none" id="hereByRd"
                              value="True" />
                            <span class="radioCircleIcon line-height-1">
                              <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                              <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                            </span>
                            <h4 class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                              I hereby nominate the following person/company to
                              act as; Trustee/Guardian on behalf of my nominee
                              should my nominee still be a minor (under age 18)
                              upon my death.
                            </h4>
                          </label>
                          <!-- <div v-for="(personal8, index) in v$.form.personal8
                            .hereRadioType.$errors" :key="index" class="invalid-feedback">
                            <span v-if="personal8.$message">{{
                              personal8.$message
                            }}</span>
                          </div> -->
                          <div class="row align-items-center">
                            <div class="col-lg-4 mb-3">
                              <div class="row gx-2">
                                <div class="col-auto">
                                  <label for="trusteeRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <!-- :class="{'is-invalid': v$.form.personal8.trusteeRadioo.$error}" -->
                                    <input type="radio" v-model="form.personal8.trusteeRadioo" value="trustee"
                                      class="inputRadioCstm d-none" id="trusteeRd" />
                                    <span class="radioCircleIcon">
                                      <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                      <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                    </span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                      Trustee
                                    </h4>
                                  </label>
                                  <!-- <div v-for="(personal8, index) in v$.form.personal8
                                    .trusteeRadioo.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                                </div>
                                <div class="col-auto">
                                  <label for="guardianRd" class="w-100 b-0 p-relative labelStyleRadioCstm pt-2 pb-2">
                                    <!-- :class="{'is-invalid': v$.form.personal8.trusteeRadioo.$error}" -->
                                    <input type="radio" v-model="form.personal8.trusteeRadioo" value="guardian"
                                      class="inputRadioCstm d-none" id="guardianRd" />
                                    <span class="radioCircleIcon">
                                      <i class="mdi mdi-circle-outline text-theme font-size-20"></i>
                                      <i class="mdi mdi-checkbox-marked-circle text-theme theme-check font-size-20"></i>
                                    </span>
                                    <h4 class="font-size-14 text-dark-custom mb-0 ms-1">
                                      Guardian
                                    </h4>
                                  </label>
                                  <!-- <div v-for="(personal8, index) in v$.form.personal8
                                    .trusteeRadioo.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Relationship to Beneficiary</label>
                                <div class="single-select2-cstm">
                                  <Select2 v-model="form.personal8.relationbenifit3" :options="relationNameOpt"
                                    @input="validatemobnumber6" />
                                  <label class="font-size-1" v-if="ispassnumberInvalid" style="color: red">Atleast 7
                                    number required</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-3" v-if="
                              form.personal8.relationbenifit3 ===
                              'Other family member'
                            ">
                            <label class="font-size-14">
                              Specify The Relation</label>
                            <input type="textarea" v-model="form.personal8.appointnominee_othere3"
                              class="form-control" />
                          </div>
                          <div class="groupFeildsNom border-top pt-3 mt-3">
                            <div class="row">
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14"> Name</label>
                                <input type="text" v-model="form.personal8.appointnominee_name3" class="form-control" />
                                <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_name3.$error}"
                                  <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_name3.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                              </div>
                              <div class="col-lg-8 mb-3">
                                <label class="font-size-14">
                                  Email Address</label>
                                <input type="email" v-model="form.personal8.appointnominee_email3" maxlength="100"
                                  class="form-control" />
                                <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_email3.$error}"
                                  <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_email3.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                              </div>
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14">
                                  Phone Number</label>
                                <input type="number" inputmode="numeric" v-model="form.personal8.appointnominee_pno3"
                                  class="form-control" @input="validatemobnumber10" />
                                <label class="font-size-1" v-if="ispassnumberInvalid10" style="color: red">Atleast 7
                                  number required</label>

                                <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_pno3.$error}"
                                  <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_pno3.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                              </div>
                              <div class="col-lg-4 mb-3">
                                <label class="font-size-14">
                                  Date of Birth</label>
                                <input type="date" v-model="form.personal8.appointnominee_dob3" maxlength="10"
                                  class="form-control" />
                                <label v-if="isInvalidDOB3" style="color: red">Date of Birth cannot be greater than the
                                  current date.</label>
                                <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_dob3.$error}"
                                  <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_dob3.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                              </div>
                              <div class="col-lg-12 mb-3">
                                <label class="font-size-14"> Address</label>
                                <input type="textarea" v-model="
                                    form.personal8.appointnominee_address3
                                  " maxlength="210" class="form-control" />
                                <!-- :class="{'is-invalid': v$.form.personal8.appointnominee_address3.$error}"
                                  <div v-for="(personal8, index) in v$.form.personal8
                                    .appointnominee_address3.$errors" :key="index" class="invalid-feedback">
                                    <span v-if="personal8.$message">{{
                                      personal8.$message
                                    }}</span>
                                  </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content class="tab_9" title="" :before-change="handleBeforeChange8">
                    <h2 class="titleStepForm">Member's Declaration</h2>
                    <div class="">
                      <p class="font-weight-500">
                        Eastern Credit Union Cooperative Society Limited is
                        required to comply with Anti- Money laundering and
                        Combating Terrorist Financing legislation (Proceeds of
                        Crime Act and Financial Obligations Regulations,
                        Financial Intelligence Unit) and the Foreign Account Tax
                        Compliance Act
                      </p>
                      <label for="hereByRd1" class="w-100 b-0 p-relative labelStyleRadioCstm pt-0 pb-0" :class="{
                          'is-invalid':
                            v$.form.personal9.hereRadioType_one.$error,
                        }">
                        <input type="checkbox" v-model="form.personal9.hereRadioType_one" value="True"
                          class="inputRadioCstm d-none" id="hereByRd1" />
                        <span class="radioCircleIcon line-height-1">
                          <i class="mdi mdi-checkbox-blank-outline text-theme font-size-22"></i>
                          <i class="mdi mdi-check-box-outline text-theme theme-check font-size-22"></i>
                        </span>
                        <h4 class="font-size-13 line-height-17 text-dark-custom font-weight-400 mb-0 ms-1">
                          I hereby apply for membership in
                          <strong class="font-weight-500">Eastern Credit Union Cooperative Society
                            Limited</strong>
                          and declare that the information given in this
                          Membership Application Form is true and correct.
                        </h4>
                      </label>
                      <div v-for="(personal9, index) in v$.form.personal9
                          .hereRadioType_one.$errors" :key="index" class="invalid-feedback">
                        <span v-if="personal9.$message">{{
                          personal9.$message
                          }}</span>
                      </div>
                      <p>
                        I agree to abide by the terms of the account(s)
                        agreement and with the Statutory Provision and bye-laws
                        governing the operations of
                        <strong class="font-weight-500">Eastern Credit Union Cooperative Society
                          Limited.</strong>
                        I am also aware that I am not a bona fide member of the
                        credit union until this application is approved by the
                        Board.
                      </p>
                    </div>
                    <div class="">
                      <div class="border-bottom pb-3 mb-3">
                        <div class="row">
                          <div class="col-lg-6 mb-3">
                            <div class="d-block">
                              <label class="font-size-14">Applicant’s Name</label>
                              <input type="text" v-model="form.personal9.appliname" class="form-control" disabled />
                              <!-- :class="{'is-invalid': v$.form.personal9.appliname.$error}"
                                <div v-for="(personal9, index) in v$.form.personal9
                                  .appliname.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal9.$message">{{
                                    personal9.$message
                                  }}</span>
                                </div> -->
                            </div>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <div class="form-group">
                              <label for="my-file">Applicant’s Signature</label>
                              <input type="file"
                                accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                @change="onFileChange6" class="form-control" id="my-file" />
                              <!-- <template v-if="preview[4]">
                               
                                <div class="border bg-white p-2 mt-3">
                                  <h4 class="font-size-15 mb-3">
                                    Applicant’s Signature Here:
                                  </h4>
                                  <img v-if="
                                    preview[4].type === 'pdf' ||
                                    preview[4].type === 'docx' ||
                                    preview[4].type === 'xlsx'
                                  " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                    class="icon-file-upload" />
                                  <img v-else :src="preview[4].value" class="img-custom-preview" />
                                  <p class="mb-0">
                                    file name: {{ image[4].name }}
                                  </p>
                                  <p class="mb-0">
                                    size: {{ image[4].size / 1024 }}KB
                                  </p>
                                </div>
                              </template> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="radioSelected1 === 'minor'">
                        <div class="border-bottom pb-3 mb-3">
                          <div class="row">
                            <div class="col-lg-6 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Name of Parent/Guardian</label>
                                <input type="text" class="form-control" v-model="form.personal9.nameofparent"
                                  disabled />
                                <!-- :class="{'is-invalid': v$.form.personal9.nameofparent.$error}"
                                <div v-for="(personal9, index) in v$.form.personal9
                                  .nameofparent.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal9.$message">{{
                                    personal9.$message
                                  }}</span>
                                </div> -->
                              </div>
                            </div>

                            <div class="col-lg-6 mb-3">
                              <div class="form-group">
                                <label for="my-file">Parent/ Guardian’s Signature
                                  <sup>(for minors)</sup></label>
                                <input type="file"
                                  accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                  @change="onFileChange7" class="form-control" id="my-file" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="radioSelected1 === 'minor'">
                        <div class="pb-3 mb-3">
                          <div class="row">
                            <div class="col-lg-6 mb-3">
                              <div class="d-block">
                                <label class="font-size-14">Name of Trustee</label>
                                <input type="text" class="form-control" v-model="form.personal9.nameoftrustee"
                                  disabled />
                                <!-- :class="{'is-invalid': v$.form.personal9.nameoftrustee.$error}"
                                <div v-for="(personal9, index) in v$.form.personal9
                                  .nameoftrustee.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="personal9.$message">{{
                                    personal9.$message
                                  }}</span>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-lg-6 mb-3">
                              <div class="form-group">
                                <label for="my-file">Trustee’s Signature
                                  <sup>(for minors)</sup></label>
                                <input type="file"
                                  accept=".docx, .doc, application/pdf,application/vnd.ms-excel/, image/jpg, image/png"
                                  @change="onFileChange8" class="form-control" id="my-file" />

                                <!-- <template v-if="preview[6]">
                                  <div class="border bg-white p-2 mt-3">
                                    <h4 class="font-size-15 mb-3">
                                      Trustee’s Signature Here:
                                    </h4>
                                    <img v-if="
                                      preview[6].type === 'pdf' ||
                                      preview[6].type === 'docx' ||
                                      preview[6].type === 'xlsx'
                                    " src="@/assets/images/ECU/icon-file.png" alt="icon-image"
                                      class="icon-file-upload" />
                                    <img v-else :src="preview[6].value" class="img-custom-preview" />
                                    <p class="mb-0">
                                      file name: {{ image[6].name }}
                                    </p>
                                    <p class="mb-0">
                                      size: {{ image[6].size / 1024 }}KB
                                    </p>
                                  </div>
                                </template> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </tab-content>
                </form-wizard>
              </template>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
</template>
<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .preloader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top-color: #f48226;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import { FormWizard, TabContent } from "vue3-form-wizard";
  import "vue3-form-wizard/dist/style.css";
  import Select2 from "vue3-select2-component";
  // import VueTelInput from 'vue-tel-input'
  import axios from "axios";
  import useVuelidate from "@vuelidate/core";
  import { required, minLength, helpers } from "@vuelidate/validators";
  import base_domain from "../../myvars";
  axios.defaults.baseURL = base_domain;

  // import CryptoJS from 'crypto-js';
  // import Layout from "../../layouts/main";
  //import ServiceSub from './sub-components/service';
  export default {
    props: {
      value: String,
      // countrynameIssuanceOpt: [
      //   { label: "INDIA (+91)", value: "+91" },
      //   { label: "PAKISTAN (+92)", value: "+92" },

      // ],
    },

    beforeRouteLeave(to, from, next) {
      // Save the form data to a reactive property or emit an event to the parent component
      this.$emit("saveFormData", this.form);
      next();
    },

    setup() {
      return { v$: useVuelidate() };
    },

    mounted() {
      // this.handlefetchdata();
      // this.submitgetid();
      setTimeout(() => {
        this.hide_entering_message = false;
      }, 4000);
      this.updateParentValue();
      this.fetchData();
      this.incSlab();
      this.usermail = localStorage.getItem("userEmail");

      // this.get_user_email();
      // this.chkStaff();
    },
    // beforemounted() {
    //   this.submitgetid();
    // },

    data() {
      return {
        showErrorMessage: false,
        hide_entering_message: true,
        errorpartone: false,
        errorpart: false,
        errorparttwo: false,
        selectedYearMdl: "",
        selectedMonthMdl: "",
        selectedYearOpt: [
          "1950",
          "1951",
          "1952",
          "1953",
          "1954",
          "1955",
          "1956",
          "1957",
          "1958",
          "1959",
          "1960",
          "1961",
          "1962",
          "1963",
          "1964",
          "1965",
          "1966",
          "1967",
          "1968",
          "1969",
          "1970",
          "1971",
          "1972",
          "1973",
          "1974",
          "1975",
          "1976",
          "1977",
          "1978",
          "1979",
          "1980",
          "1981",
          "1982",
          "1983",
          "1984",
          "1985",
          "1986",
          "1987",
          "1988",
          "1989",
          "1990",
          "1991",
          "1992",
          "1993",
          "1994",
          "1995",
          "1996",
          "1997",
          "1998",
          "1999",
          "2000",
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
          "2028",
          "2029",
          "2030",
          "2031",
          "2032",
          "2033",
          "2034",
          "2035",
          "2036",
          "2037",
          "2038",
          "2039",
          "2040",
          "2041",
          "2042",
          "2043",
          "2044",
          "2045",
          "2046",
          "2047",
          "2048",
          "2049",
          "2050",
          "2051",
          "2052",
          "2053",
          "2054",
          "2055",
          "2056",
          "2057",
          "2058",
          "2059",
        ],
        selectedMonthOpt: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        maxLength: 11,
        selectedCountryCode: "", // Initially selected country code
        phoneNumber: this.value || "", // Initially entered phone number
        selectedFile1: null,
        selectedFile2: null,
        selectedFile3: null,
        selectedFile4: null,
        selectedFile5: null,
        selectedFile6: null,
        selectedFile7: null,
        selectedFile8: null,
        selectedFile9: null,
        usermail: "",
        optionsList: [],
        startIndex: "",
        app_id: "",
        // fileSelected: [false, false, false, false, false],
        isLoading: false,
        // radioSelected1: "minor",
        radioSelected1: "",
        invalidDOBMinor: false,
        // dobCount:0,
        backendValue: "",
        checkFeildInput1: "",
        checkFeildInput2: "",
        riskInput: "",
        app_URL: process.env.VUE_APP_URL,
        showValue: true,
        fatcaFeild: "",
        checkFeildInput: [],
        radioSelected: "",
        // nationalIssuance: "Trinidad and Tobago",
        // driverIssuance: "Trinidad and Tobago",
        // passportIssuance: "Trinidad and Tobago",
        // birthIssuance: "",
        trustee_id: "",
        gurdian_id: "",
        // files:'',
        showRadio: false,
        preview: [],
        image: [],
        averageIncmOpt: [
          "under $1500",
          "$1,501-$3,000",
          "$3,001-$4,500",
          "$4,501-$6,000",
          "$6,001-$7500",
          "$7,501-$9,000",
          "$9,001-$11,000",
          "over $11,000",
        ],
        nationalityOpt: [
          "Afghanistan",
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bhutan",
          "Bolivia",
          "Bosnia and Herzegovina",
          "Botswana",
          "Brazil",
          "Brunei",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Côte d voire",
          "Cabo Verde",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Colombia",
          "Comoros",
          "Congo Congo-Brazzaville)",
          "Costa Rica",
          "Croatia",
          "Cuba",
          "Cyprus",
          "Czechia (Czech Republic)",
          "Democratic Republic of the Congo",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Eswatini",
          "Ethiopia",
          "Fiji",
          "Finland",
          "France",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Greece",
          "Grenada",
          "Guatemala",
          "Guinea",
          "Guinea-Bissau",
          "Guyana",
          "Haiti",
          "Holy See",
          "Honduras",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Iran",
          "Iraq",
          "Ireland",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Kuwait",
          "Kyrgyzstan",
          "Laos",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Mauritania",
          "Mauritius",
          "Mexico",
          "Micronesia",
          "Moldova",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Myanmar (formerly Burma)",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "North Korea",
          "North Macedonia (formerly Macedonia)",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestine State",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russia",
          "Rwanda",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome nd Princip",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Slovakia",
          "Slovenia",
          "Solomon slands",
          "Somalia",
          "South Africa",
          "South Korea",
          "South Sudan",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Sweden",
          "Switzerland",
          "Syria",
          "Tajikistan",
          "Tanzania",
          "Thailand",
          "Timor-Leste",
          "Togo",
          "Tonga",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Tuvalu",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States of America",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Venezuela",
          "Vietnam",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],

        country_nameOpt: [
          "Afghanistan",
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bhutan",
          "Bolivia",
          "Bosnia and Herzegovina",
          "Botswana",
          "Brazil",
          "Brunei",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Côte d voire",
          "Cabo Verde",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Colombia",
          "Comoros",
          "Congo Congo-Brazzaville)",
          "Costa Rica",
          "Croatia",
          "Cuba",
          "Cyprus",
          "Czechia (Czech Republic)",
          "Democratic Republic of the Congo",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Eswatini",
          "Ethiopia",
          "Fiji",
          "Finland",
          "France",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Greece",
          "Grenada",
          "Guatemala",
          "Guinea",
          "Guinea-Bissau",
          "Guyana",
          "Haiti",
          "Holy See",
          "Honduras",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Iran",
          "Iraq",
          "Ireland",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Kuwait",
          "Kyrgyzstan",
          "Laos",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Mauritania",
          "Mauritius",
          "Mexico",
          "Micronesia",
          "Moldova",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Myanmar (formerly Burma)",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "North Korea",
          "North Macedonia (formerly Macedonia)",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestine State",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russia",
          "Rwanda",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome nd Princip",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Slovakia",
          "Slovenia",
          "Solomon slands",
          "Somalia",
          "South Africa",
          "South Korea",
          "South Sudan",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Sweden",
          "Switzerland",
          "Syria",
          "Tajikistan",
          "Tanzania",
          "Thailand",
          "Timor-Leste",
          "Togo",
          "Tonga",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Tuvalu",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States of America",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Venezuela",
          "Vietnam",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],

        titleNameOpt: ["Mr.", "Mrs.", "Miss."],
        relationNameOpt: ["Father", "Mother", "Friends", "Other family member"],
        branchNameOpt: [
          "Arima",
          "Barataria",
          "Chaguanas",
          "Port of Spain",
          "Sande Grande",
          "San Fernando",
          "St. Joseph - La Joya",
          "Tobago",
        ],
        // preview_list: [],
        // image_list: [],
        // fundedoption: [
        //   "Salary Deduction",
        //   "Standing Order from Financial Institution",
        //   "Over the Counter Deposits",
        //   "Other",
        // ],
        fundedoption: [
          "Salary",
          "Allowance from spouse or parent",
          "Business proceeds",
          "Pension",
          "Standing Order from Financial Institution",
          "Other",
        ],

        Incomeoption: [
          "Salaried",
          "Business",
          "Unemployed",
          "Retired",
          "Self-employed",
        ],

        countrynameIssuanceOpt: [
          "+93-AFG",
          "+355-ALB",
          "+213-DZA",
          "+376-AND",
          "+244-AGO",
          "+1-ATG",
          "+54-ARG",
          "+374-ARM",
          "+297-AABW",
          "+61-AUS",
          "+43-AUT",
          "+994-AZE",
          "+1-BHS",
          "+973-BHR",
          "+880-BGD",
          "+1-BRB",
          "+375-BLR",
          "+32-BEL",
          "+501-BLZ",
          "+229-BEN",
          "975-BTN",
          "+591-BOL",
          "+387-BIH",
          "+267-BWA",
          "+55-BRA",
          "+673-BRN",
          "+359-BGR",
          "+226-BFA",
          "+257-BDI",
          "+855-KHM",
          "+237-CMR",
          "+1-CAN",
          "+238-CPV",
          "+236-CAF",
          "+235-TCD",
          "+56-CHL",
          "+86-CHN",
          "+57-COL",
          "+269-COM",
          "+242-COG",
          "+682-COK",
          "506-CRI",
          "+225-CIV",
          "+385-HRV",
          "+53-CUB",
          "+357-CYP",
          "+420-CZE",
          "+45-DNK",
          "+253-DJI",
          "+1-DMA",
          "+1-DOM",
          "+593-ECU",
          "20-EGY",
          "+503-SLV",
          "+240-GNQ",
          "+291-ERI",
          "+372-EST",
          "+251-ETH",
          "+500-FLK",
          "+298-FRO",
          "+679-FJI",
          "+358-FIN",
          "+33-FRA",
          "+689-PYF",
          "+241-GAB",
          "+220-GMB",
          "+995-GEO",
          "+49-DEU",
          "+233-GHA",
          "+350-GIB",
          "+30-GRC",
          "+299-GRL",
          "+1-GRD",
          "+502-GTM",
          "224-GIN",
          "+245-GNB",
          "+592-GUY",
          "+509-HTI",
          "+504-HND",
          "+852-HKG",
          "+36-HUN",
          "+354-ISL",
          "+91-IND",
          "+62-IDN",
          "+98-IRN",
          "964-IRQ",
          "+353-IRL",
          "+44-IMN",
          "+972-ISR",
          "+39-ITA",
          "+1-JAM",
          "+81-JPN",
          "+962-JOR",
          "+7-KAZ",
          "+254-KEN",
          "+686-KIR",
          "383-XKX",
          "+850-PRK",
          "+82-KOR",
          "+965-KWT",
          "+996-KGZ",
          "+856-LAO",
          "+371-LVA",
          "+961-LBN",
          "+266-LSO",
          "+231-LBR",
          "+218-LBY",
          "+423-LIE",
          "+370-LTU",
          "+352-LUX",
          "+853-MAC",
          "+389-MKD",
          "+261-MDG",
          "+265-MWI",
          "+60-MYS",
          "+960-MDV",
          "+223-MLI",
          "+356-MLT",
          "+692-MHL",
          "+222-MRT",
          "+230-MUS",
          "+262-REU",
          "+52-MEX",
          "+691-FSM",
          "+373-MDA",
          "+377-MCO",
          "+976-MNG",
          "+382-MNE",
          "+1-MSR",
          "+212-MAR",
          "+258-MOZ",
          "+95-MMR",
          "+264-NAM",
          "+674-NRU",
          "+977-NPL",
          "+31-NLD",
          "+599-NLD",
          "687-NCL",
          "+64-NZL",
          "+505-NIC",
          "+227-NER",
          "+234-NGA",
          "+683-NIU",
          "+672-NFK",
          "+47-NOR",
          "+968-OMN",
          "+92-PAK",
          "+680-PLW",
          "970-PSE",
          "+507-PAN",
          "+675-PNG",
          "+595-PRY",
          "+51-PER",
          "+63-PHL",
          "+48-POL",
          "+351-PRT",
          "+1-PRK",
          "+974-QAT",
          "+40-ROU",
          "7-RUS",
          "+250-RWA",
          "+590-BLM",
          "+290-SHN",
          "+1-KNA",
          "+1-LCA",
          "+590-MFA",
          "+508-SPM",
          "+1-VCT",
          "+685-WSM",
          "+378-SMR",
          "239-STP",
          "+966-SAU",
          "+221-SEN",
          "+381-SRB",
          "+248-SYC",
          "+232-SLE",
          "+65-SGP",
          "+599-SXM",
          "+421-SVK",
          "+386-SVN",
          "+677-SLB",
          "+252-SOM",
          "+27-ZAF",
          "+211-SSD",
          "+34-ESP",
          "+94-LKA",
          "+249-SDN",
          "+597-SUR",
          "+268-SWZ",
          "+46-SWE",
          "+41-CHE",
          "963-SYR",
          "+886-TWN",
          "+992-TJK",
          "+255-TZA",
          "+66-THA",
          "+670-TLS",
          "+228-TGO",
          "+690-TKL",
          "+676-TON",
          "+868-TTO",
          "+216-TUN",
          "90-TUR",
          "+993-TKM",
          "+688-TUV",
          "+256-UGA",
          "+380-UKR",
          "+971-ARE",
          "+44-GBR",
          "+1-USA",
          "+598-URY",
          "+998-UZB",
          "+678-VUT",
          "39-VA",
          "+58-VEN",
          "+84-VNM",
          "+681-WLF",
          "+212-ESH",
          "+967-YEM",
          "+260-ZMB",
          "+263-ZWE",
        ],
        nationalIssuanceOpt: [
          "Afghanistan",
          "Aland Islands",
          "Albania",
          "Algeria",
          "American Samoa",
          "Andorra",
          "Angola",
          "Anguilla",
          "Antarctica",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Aruba",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bermuda",
          "Bhutan",
          "Bolivia",
          "Bonaire",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bouvet Island",
          "Brazil",
          "British Indian Ocean Territory",
          "British Virgin Islands",
          "Brunei",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Cape Verde",
          "Cayman Islands",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Christmas Island",
          "Cocos (Keeling) Islands",
          "Colombia",
          "Comoros",
          "Congo (Brazzaville)",
          "Congo (Kinshasa)",
          " Cook Islands",
          "Costa Rica",
          "Croatia",
          "Curaçao",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "East Timor",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Falkland Islands",
          "Faroe Islands",
          "Fiji",
          "Finland",
          "France",
          "French Guiana",
          "French Polynesia",
          "French Southern Territories",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Grenada",
          "Guadeloupe",
          "Guam",
          "Guatemala",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "uyana",
          "Haiti",
          "Heard Island and McDonald Islands",
          "Honduras",
          "Hong Kong",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jersey",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Kuwait",
          "Kyrgyzstan",
          "Laos",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macau",
          "Macedonia",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Martinique",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Mexico",
          "Micronesia",
          "Moldova",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Montserrat",
          "Morocco",
          "Mozambique",
          "Myanmar",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Caledonia",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "Niue",
          "Norfolk Island",
          "Northern Mariana Islands",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestinian Territories",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Pitcairn",
          "Poland",
          "Portugal",
          "Puerto Rico",
          "Qatar",
          "Republic of Côte d’Ivoire",
          "Republic of Kosovo",
          "Reunion",
          "Romania",
          "Russia",
          "Rwanda",
          "Saint Barthélemy",
          "Saint Helena",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Sint Maarten",
          "Slovakia",
          "Slovenia",
          "Solomon Islands",
          "Somalia",
          "South Africa",
          "South Georgia and the South Sandwich Islands",
          "South Korea",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Switzerland",
          "Taiwan",
          "Tajikistan",
          "Tanzania",
          "Thailand",
          "Togo",
          "Tokelau",
          "Tonga",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Turks and Caicos Islands",
          "Tuvalu",
          "U.S. Virgin Islands",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States",
          "United States Minor Outlying Islands",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Vatican",
          "Venezuela",
          "Vietnam",
          "Wallis and Futuna",
          "Western Sahara",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        showMessageAfterClearingExpiry: false,
        showMessageAfterClearing: false,
        showMessageAfterClearingdid: false,
        isInvalidDOB: false,
        isInvalidDOB1: false,
        isInvalidDOB2: false,
        isInvalidDOB3: false,

        form: {
          personal1: {
            branchName: "",
            // personNum: "",
            // memberNum: "",
            title: "",
            surname: "",
            firstName: "",
            // middleName: "",
            // address: "",
            // geoCode: "",
            // mailingAddress: "",
            address_line: "",
            address_line_two: "",
            city_name: "",
            state_name: "",
            zip_code: "",
            country_name: "Trinidad and Tobago",
            dob: "",
            gender: "",
            numOfDependents: "",
            placeOfdob: "",
            nationality: "Trinidad and Tobago",
            maritalStatus: "",
            // nationalIdNum: "",
            // nidIssueDate: "",
            // nidExpiry: "",
            nationalIssuance: "Trinidad and Tobago",
            // drivingIdNum: "",
            // didIssueDate: "",
            // didExpiry: "",
            driverIssuance: "Trinidad and Tobago",
            // passportNum: "",
            // passIssueDate: "",
            // passExpiry: "",
            passportIssuance: "Trinidad and Tobago",
            birthIdNum: "",
            birthIssuance: "Trinidad and Tobago",
            homePhoneNum: "",
            homePhoneNumcode: "",
            mobileNumcode: "",

            mobileNum: "",
            email: "",
            // nidDoc: [],
            // didDoc: [],
            // passDoc: [],
            // birthDoc: [],
          },
          personal2: {
            employStatus: "",
            frequencyStatus: "",
            averageIncm: "",
            user_occupation: "",
            employer: "",
            user_employer: "",
            user_w_phone_no: "",
            user_w_phone_nocode: "",
            user_phone_no: "",
            user_isstudent: "",
            user_howlong: "",
            user_howlong2: "",
          },
          personal3: {
            accFunded: "",
            accountActi: "",
            // accountActiDesc: "",
            numTrans: "",
            // socuceofincome: "",
            areaofdetails: "",
            volTrans: "",
            // other_details:"",
          },
          personal4: {
            parentName: "",
            parentId: "",
            trusteeName: "",
            trusteeId: "",
            advisor: "",
            declaration: "",
          },
          personal5: {
            entrustedRadio: "",
            memberOfRadio: "",
            referredRadio: "",
          },
          personal6: {
            tobagoRadio: "",
            citizenRadio: "",
            // tobagoRadioDes:"",
            ssn_no: "",
            addressRadio: "",
            incomeUsRadio: "",
          },
          personal7: {
            account_one: "",
            shares_amount: "",
            account_two: "",
            special_shares_amount: "",
            account_three: "",
            deposit_ac_amount: "",
            reg_fee_amount: "",
            total_amount: "",
          },
          personal8: {
            appointnominee_name1: "",
            appointnominee_email1: "",
            appointnominee_pno1: "",
            appointnominee_pno1code: "",
            appointnominee_otherel: "",
            appointnominee_rel1: "",
            appointnominee_address1: "",
            appointnominee_dob1: "",

            appointnominee_name2: "",
            appointnominee_email2: "",
            appointnominee_pno2: "",
            appointnominee_pno2code: "",
            appointnominee_rel2: "",
            appointnominee_othere2: "",
            appointnominee_address2: "",
            appointnominee_dob2: "",

            hereRadioType: true,
            trusteeRadioo: "",
            // relationbenifit3: "",
            appointnominee_othere3: "",
            appointnominee_name3: "",
            appointnominee_pno3: "",
            appointnominee_email3: "",
            appointnominee_dob3: "",
            appointnominee_address3: "",
          },
          personal9: {
            hereRadioType_one: "",
            // appliname: "",
            // nameofparent:"",
            // nameoftrustee:"",
          },
        },
      };
    },
    // computed: {

    // },
    computed: {
      combinedValue() {
        return this.selectedCountryCode
          ? `+${this.selectedCountryCode} ${this.phoneNumber}`
          : this.phoneNumber;
      },
      validationError() {
        return !/^\+\d+\s\d+$/.test(this.combinedValue);
      },
      isSSNRequired() {
        return (
          this.form.personal6.citizenRadio === "True" ||
          this.form.personal6.citizenRadio === ""
        );
      },
      // --------------------------------------------------------------
      isIssueDateInvalid() {
        if (!this.form.personal1.nidIssueDate || !this.form.personal1.nidExpiry) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.nidIssueDate) >=
          new Date(this.form.personal1.nidExpiry)
        );
      },
      isExpiryDateInvalid() {
        if (!this.form.personal1.nidIssueDate || !this.form.personal1.nidExpiry) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.nidExpiry) <=
          new Date(this.form.personal1.nidIssueDate)
        );
      },
      isIssueDateFuture() {
        if (!this.form.personal1.nidIssueDate) {
          return false; // Don't show the error message if the Issue Date is empty
        }
        return new Date(this.form.personal1.nidIssueDate) > new Date();
      },
      // -------------------------------------------------------------
      isdidIssueDateInvalid() {
        if (!this.form.personal1.didIssueDate || !this.form.personal1.didExpiry) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.didIssueDate) >=
          new Date(this.form.personal1.didExpiry)
        );
      },
      isdidExpiryDateInvalid() {
        if (!this.form.personal1.didIssueDate || !this.form.personal1.didExpiry) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.didExpiry) <=
          new Date(this.form.personal1.didIssueDate)
        );
      },
      isdidIssueDateFuture() {
        if (!this.form.personal1.didIssueDate) {
          return false; // Don't show the error message if the Issue Date is empty
        }
        return new Date(this.form.personal1.didIssueDate) > new Date();
      },
      //------------------------------------------------
      ispassIssueDateInvalid() {
        if (
          !this.form.personal1.passIssueDate ||
          !this.form.personal1.passExpiry
        ) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.passIssueDate) >=
          new Date(this.form.personal1.passExpiry)
        );
      },

      ispassExpiryDateInvalid() {
        if (
          !this.form.personal1.passIssueDate ||
          !this.form.personal1.passExpiry
        ) {
          return false; // Don't show the error message if any date is empty
        }
        return (
          new Date(this.form.personal1.passExpiry) <=
          new Date(this.form.personal1.passIssueDate)
        );
      },
      ispassIssueDateFuture() {
        if (!this.form.personal1.passIssueDate) {
          return false; // Don't show the error message if the Issue Date is empty
        }
        return new Date(this.form.personal1.passIssueDate) > new Date();
      },
    },

    watch: {
      value(newValue) {
        this.selectedCountryCode = this.extractCountryCode(newValue);
        this.phoneNumber = this.extractPhoneNumber(newValue);
      },
      // 'form.personal6.citizenRadio': function (newCitizenRadio) {
      //     if (newCitizenRadio === 'False') {
      //       this.ssnNumber = this.ssnNumber || 'ssn_null';
      //     }
      //   },
      "form.personal1.dob": function (newDOB) {
        // this.radioSelected1= 'minor';
        if (newDOB && new Date(newDOB) > new Date()) {
          this.isInvalidDOB = true;
          // Clear the "Date of Birth" if it's invalid
          this.form.personal1.dob = "";
          // Set a timer to hide the error message after 2 seconds
          setTimeout(() => {
            this.isInvalidDOB = false;
          }, 2000);
        }
        // if (newDOB) {
        //     this.radioSelected1 = 'adult';
        //     this.dobCount++;
        //     console.log('chck the count',this.dobCount)
        //     const currentDate = new Date();
        //     const inputDate = new Date(newDOB);
        //     const ageDiffMilliseconds = currentDate - inputDate;
        //     const ageDate = new Date(ageDiffMilliseconds);
        //     const age = Math.abs(ageDate.getUTCFullYear() - 1970);

        //     if (age < 18) {
        //       this.radioSelected1 = 'minor';
        //     } else {
        //       this.radioSelected1 = 'adult';
        //     }
        //   }
        //   if (this.dobCount >= 7) {
        //     location.reload();
        //     return;
        //   }
        // },

        if (newDOB) {
          const currentDate = new Date();
          const inputDate = new Date(newDOB);
          const ageDiffMilliseconds = currentDate - inputDate;
          const ageDate = new Date(ageDiffMilliseconds);
          const age = Math.abs(ageDate.getUTCFullYear() - 1970);
          if (this.radioSelected1 === "minor" && age >= 18) {
            // If 'minor' is selected but the entered age is 18 or older
            this.invalidDOBMinor = true;
            this.form.personal1.dob = "";
            // this.radioSelected1 = 'minor';
            setTimeout(() => {
              this.invalidDOBMinor = false;
            }, 2000);
          } else if (this.radioSelected1 === "adult" && age < 18) {
            // If 'adult' is selected but the entered age is less than 18
            this.invalidDOBadult = true;
            this.form.personal1.dob = "";
            // this.radioSelected1 = 'adult';
            setTimeout(() => {
              this.invalidDOBadult = false; // Fix: Set to false after timeout, not true
            }, 2000);
          } else {
            // Clear any previous invalid DOB messages
            // this.invalidDOBMinor = false;
            // this.invalidDOBadult = false;
          }
        } else {
          // Clear any previous invalid DOB messages if no DOB is entered
          // this.invalidDOBMinor = false;
          // this.invalidDOBadult = false;
        }
      },

      "form.personal8.appointnominee_dob1": function (newDOB) {
        if (newDOB && new Date(newDOB) > new Date()) {
          this.isInvalidDOB1 = true;
          // Clear the "Date of Birth" if it's invalid
          this.form.personal8.appointnominee_dob1 = "";
          // Set a timer to hide the error message after 2 seconds
          setTimeout(() => {
            this.isInvalidDOB1 = false;
          }, 2000);
        }
      },
      "form.personal8.appointnominee_dob2": function (newDOB) {
        if (newDOB && new Date(newDOB) > new Date()) {
          this.isInvalidDOB2 = true;
          // Clear the "Date of Birth" if it's invalid
          this.form.personal8.appointnominee_dob2 = "";
          // Set a timer to hide the error message after 2 seconds
          setTimeout(() => {
            this.isInvalidDOB2 = false;
          }, 2000);
        }
      },
      "form.personal8.appointnominee_dob3": function (newDOB) {
        if (newDOB && new Date(newDOB) > new Date()) {
          this.isInvalidDOB3 = true;
          // Clear the "Date of Birth" if it's invalid
          this.form.personal8.appointnominee_dob3 = "";
          // Set a timer to hide the error message after 2 seconds
          setTimeout(() => {
            this.isInvalidDOB3 = false;
          }, 2000);
        }
      },
      "form.personal1.nidIssueDate": function (newIssueDate) {
        if (newIssueDate && this.form.personal1.nidExpiry) {
          if (new Date(newIssueDate) >= new Date(this.form.personal1.nidExpiry)) {
            this.showMessageAfterClearingExpiry = true;
            // this.form.personal1.nidExpiry = ''; // Clear the "Expiry Date" if it's invalid
          } else {
            this.showMessageAfterClearingExpiry = false;
          }
        }
      },
      "form.personal1.nidExpiry": function (newExpiryDate) {
        if (newExpiryDate && this.form.personal1.nidIssueDate) {
          if (
            new Date(newExpiryDate) <= new Date(this.form.personal1.nidIssueDate)
          ) {
            this.showMessageAfterClearingExpiry = true;
            // this.form.personal1.nidIssueDate = ''; // Clear the "Issue Date" if it's invalid
          } else {
            this.showMessageAfterClearingExpiry = false;
          }
        }
      },
      "form.personal1.didIssueDate": function (newIssueDate) {
        if (newIssueDate && this.form.personal1.didExpiry) {
          if (new Date(newIssueDate) >= new Date(this.form.personal1.didExpiry)) {
            this.showMessageAfterClearingdid = true;
            // this.form.personal1.didExpiry = ''; // Clear the "Expiry Date" if it's invalid
            setTimeout(() => {
              this.showMessageAfterClearingdid = false;
            }, 2000);
          } else {
            this.showMessageAfterClearingdid = false;
          }
        }
      },
      "form.personal1.didExpiry": function (newExpiryDate) {
        if (newExpiryDate && this.form.personal1.didIssueDate) {
          if (
            new Date(newExpiryDate) <= new Date(this.form.personal1.didIssueDate)
          ) {
            this.showMessageAfterClearing = true;
            // this.form.personal1.didIssueDate = ''; // Clear the "Issue Date" if it's invalid
          } else {
            this.showMessageAfterClearing = false;
          }
        }
      },
    },

    validations: {
      form: {
        personal1: {
          branchName: {
            required: helpers.withMessage("Branch Name is required...", required),
          },
          // personNum: {
          //   required: helpers.withMessage("Person Number is required...", required),
          // },
          // memberNum: {
          //   required: helpers.withMessage("Member Name is required...", required),
          // },
          title: {
            required: helpers.withMessage("Member Name is required...", required),
          },
          surname: {
            required: helpers.withMessage("Surname is required...", required),
          },
          firstName: {
            required: helpers.withMessage("First Name is required...", required),
          },
          // middleName: {
          //   required: helpers.withMessage("Middle Name is required...", required),
          // },
          // address: {
          //   required: helpers.withMessage("Address is required...", required),
          // },
          // geoCode: {
          //   required: helpers.withMessage("GeoCode is required...", required),
          // },
          // mailingAddress: {
          //   required: helpers.withMessage("Mailing Address is required...",required),
          // },
          address_line: {
            required: helpers.withMessage("Address is required...", required),
          },
          // address_line_two:{
          //     required: helpers.withMessage("Address is required...",required),
          // },
          city_name: {
            required: helpers.withMessage("City Name is required...", required),
          },
          // state_name:{
          //     required: helpers.withMessage("State Name is required...",required),
          // },
          // zip_code:{
          //     required: helpers.withMessage("Zip Code is required...",required),
          // },
          country_name: {
            required: helpers.withMessage(
              "Country Name is required...",
              required
            ),
          },
          maritalStatus: {
            required: helpers.withMessage("Marital is required...", required),
          },
          dob: {
            required: helpers.withMessage(
              "Date of Birth is required...",
              required
            ),
          },
          gender: {
            required: helpers.withMessage("Gender is required...", required),
          },
          numOfDependents: {
            required: helpers.withMessage("Dependents is required...", required),
          },
          placeOfdob: {
            required: helpers.withMessage("Place is required...", required),
          },
          nationality: {
            required: helpers.withMessage("Nationality is required...", required),
          },
          // nationalIdNum: {
          //   required: helpers.withMessage("National Id Num is required...",required),
          // },
          // nidIssueDate: {
          //   required: helpers.withMessage("NID Issue Date is required...", required),
          // },
          // nidExpiry: {
          //   required: helpers.withMessage("NID Expiry Date is required...", required),
          // },
          // nationalIssuance: {
          //   required: helpers.withMessage("National Issuance is required...", required),
          // },
          // drivingIdNum: {
          //   required: helpers.withMessage("Permit No is required...", required),
          // },
          // didIssueDate: {
          //   required: helpers.withMessage("Issue Date is required...", required),
          // },
          // didExpiry: {
          //   required: helpers.withMessage("Expiry Date is required...", required),
          // },
          // driverIssuance: {
          //   required: helpers.withMessage("Issuance is required...", required),
          // },
          // passportNum: {
          //   required: helpers.withMessage("Passport No is required...", required),
          // },
          // passIssueDate: {
          //   required: helpers.withMessage("Issue Date is required...", required),
          // },
          // passExpiry: {
          //   required: helpers.withMessage("Passport Expiry Date is required...", required),
          // },
          // passportIssuance: {
          //   required: helpers.withMessage("Issuance is required...", required),
          // },
          // birthIdNum: {
          //   required: helpers.withMessage("Birthday is required...", required),
          // },
          // birthIssuance: {
          //   required: helpers.withMessage("Issurance is required...", required),
          // },
          homePhoneNum: {
            required: helpers.withMessage(
              "Home Phone No is required...",
              required
            ),
            minLength: helpers.withMessage(
              "Mobile No should be at least 7 characters long...",
              minLength(7)
            ),
          },
          homePhoneNumcode: {
            required: helpers.withMessage("Required...", required),
          },
          // mobileNum: {
          //   required: helpers.withMessage("Mobile No is required...", required),
          //   minLength: helpers.withMessage(
          //     "Mobile No should be at least 7 characters long...",
          //     minLength(7)
          //   ),
          // },
          // mobileNumcode: {
          //   required: helpers.withMessage("Required...", required),
          // },
          email: {
            required: helpers.withMessage(
              "Make Sure Email is Correct...",
              required
            ),
            // email: helpers.withMessage("Enter a valid Email", email),
          },
        },
        personal2: {
          // -------------------------------------------section 2-----------------
          employStatus: {
            required: helpers.withMessage("Required...", required),
          },
          frequencyStatus: {
            required: helpers.withMessage("Required...", required),
          },
          user_howlong: {
            required: helpers.withMessage("Month is required...", required),
          },
          user_howlong2: {
            required: helpers.withMessage("Year is required...", required),
          },
          averageIncm: {
            required: helpers.withMessage("Required...", required),
          },
          user_occupation: {
            required: helpers.withMessage("Occupation is required...", required),
          },
          employer: {
            required: helpers.withMessage("Employer is required...", required),
          },
          user_employer: {
            required: helpers.withMessage(
              "Employer address is required...",
              required
            ),
          },
          user_w_phone_no: {
            required: helpers.withMessage("Phone is required...", required),
            minLength: helpers.withMessage(
              "Should be at least 7 characters long...",
              minLength(7)
            ),
          },
          user_w_phone_nocode: {
            required: helpers.withMessage("Required...", required),
          },
          // user_phone_no: {
          //   required: helpers.withMessage("Ext. is required...", required),
          // },
          // user_isstudent: {
          //   required: helpers.withMessage("School is required...", required),
          // },
        },
        personal3: {
          // ----------------------section 3-------------------------------------------------------------------------------
          accFunded: {
            required: helpers.withMessage("Fund is required...", required),
          },
          accountActi: {
            required: helpers.withMessage("This is required...", required),
          },
          // accountActiDesc:{
          //   required: helpers.withMessage("This is required...",required),
          // },
          // other_details:{
          //   required: helpers.withMessage("This is required...",required),
          // },
          // socuceofincome: {
          //   required: helpers.withMessage("Income is required...", required),
          // },
          areaofdetails: {
            required: helpers.withMessage("Details is required...", required),
          },
          numTrans: {
            required: helpers.withMessage("Transaction is required...", required),
          },
          volTrans: {
            required: helpers.withMessage("Transaction is required...", required),
          },
        },
        personal4: {
          // -------------------section 4-----------MINOR------------------
          parentName: {
            required: helpers.withMessage("Parent Name is required...", required),
          },
          parentId: {
            required: helpers.withMessage("Parent Id is required...", required),
          },
          trusteeName: {
            required: helpers.withMessage(
              "Trustee Name is required...",
              required
            ),
          },
          trusteeId: {
            required: helpers.withMessage("Trustee id is required...", required),
          },
          declaration: {
            required: helpers.withMessage("Declaration is required...", required),
          },
        },
        personal5: {
          // ------------------SECTION  5------------PEP-------------
          entrustedRadio: {
            required: helpers.withMessage("Required...", required),
          },
          memberOfRadio: {
            required: helpers.withMessage("Required...", required),
          },
          referredRadio: {
            required: helpers.withMessage("Required...", required),
          },
        },
        personal6: {
          // -----------------SECTION 6 --------FATCA----------
          tobagoRadio: {
            required: helpers.withMessage("Required...", required),
          },
          citizenRadio: {
            required: helpers.withMessage("Required...", required),
          },
          ssn_no: {
            required: helpers.withMessage("SSN no is  required...", required),
          },
          addressRadio: {
            required: helpers.withMessage("Required...", required),
          },
          incomeUsRadio: {
            required: helpers.withMessage("Required...", required),
          },
        },
        personal7: {
          //---------------------------SECTION 7 ---------Account/Fees---
          account_one: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          account_two: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          account_three: {
            required: helpers.withMessage(
              "Account Number is required...",
              required
            ),
          },
          shares_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          special_shares_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          deposit_ac_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          reg_fee_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
          total_amount: {
            required: helpers.withMessage("Amount is required...", required),
          },
        },
        personal8: {
          // // appointment of nominee-----section 8--------------
          appointnominee_name1: {
            required: helpers.withMessage("Name is required...", required),
          },
          appointnominee_email1: {
            required: helpers.withMessage("Email is required...", required),
          },
          appointnominee_pno1: {
            required: helpers.withMessage("Phone No is required...", required),
            minLength: helpers.withMessage(
              "Mobile No should be at least 7 characters long...",
              minLength(7)
            ),
          },
          appointnominee_pno1code: {
            required: helpers.withMessage("Required...", required),
          },
          appointnominee_rel1: {
            required: helpers.withMessage("Relation is required...", required),
          },
          appointnominee_address1: {
            required: helpers.withMessage("Address is required...", required),
          },
          appointnominee_dob1: {
            required: helpers.withMessage(
              "Date of birth is required...",
              required
            ),
          },

          // appointnominee_name2:{
          //   required: helpers.withMessage("Name is required...",required),
          // },
          // appointnominee_email2:{
          //   required: helpers.withMessage("Email is required...",required),
          // },
          // appointnominee_pno2:{
          //   required: helpers.withMessage("Phone No is required...",required),
          //   minLength: helpers.withMessage("Mobile No should be at least 7 characters long...", minLength(7)),
          // },
          // appointnominee_pno2code:{
          //   required: helpers.withMessage("Required...",required),
          // },
          // appointnominee_rel2:{
          //   required: helpers.withMessage("Relation is required...",required),
          // },
          // appointnominee_address2:{
          //   required: helpers.withMessage("Address is required...",required),
          // },
          // appointnominee_dob2:{
          //   required: helpers.withMessage("Date of birth is required...",required),
          // },
          // hereRadioType:{
          //   required: helpers.withMessage("Required...",required),
          // },
          // trusteeRadioo:{
          //   required: helpers.withMessage("Required...",required),
          // },
          // relationbenifit3:{
          //   required: helpers.withMessage("Relationship is required...",required),
          // },
          // appointnominee_name3:{
          //   required: helpers.withMessage("Name is required...",required),
          // },
          // appointnominee_email3:{
          //   required: helpers.withMessage("Email is required...",required),
          // },
          // appointnominee_pno3:{
          //   required: helpers.withMessage("Phone No is required...",required),
          // },
          // appointnominee_dob3:{
          //   required: helpers.withMessage("Date of Birth is required...",required),
          // },
          // appointnominee_address3:{
          //   required: helpers.withMessage("Address is required...",required),
          // },
        },
        personal9: {
          //-----------------------------------------------section 9-----------------------
          hereRadioType_one: {
            required: helpers.withMessage("Required...", required),
          },
          // appliname:{
          //   required: helpers.withMessage("Applicant name is required...",required),
          // },
          // nameofparent:{
          //   required: helpers.withMessage("Parent/Guardian is required...",required),
          // },
          // nameoftrustee: {
          //   required: helpers.withMessage("Trustee name is required...", required),
          // },
        },
      },
      // agreeAccept: {
      //   checked: value => value === true,
      // }
    },
    components: {
      FormWizard,
      TabContent,
      Layout,
      Select2,
      //   beforeRouteLeave(to, from, next) {
      //   // Save the form data to a reactive property or emit an event to the parent component
      //   this.$emit('saveFormData', this.form);
      //   next();
      // }
    },

    deactivated() {
      console.log("deactivate");
    },

    methods: {
      isPdf(type) {
        return type === 'pdf';
      },
      extractCountryCode(value) {
        const match = value.match(/^\+(\d+)\s/);
        return match ? match[1] : "";
      },
      extractPhoneNumber(value) {
        const match = value.match(/^\+\d+\s(.*)/);
        return match ? match[1] : "";
      },
      validatePhoneNumber() {
        this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
      },
      updateParentValue() {
        const newValue = this.combinedValue;
        this.$emit("input", newValue);
      },
      validateZipCode() {
        if (this.form.personal1.zip_code !== null) {
          this.form.personal1.zip_code = this.form.personal1.zip_code
            .toString()
            .slice(0, 5);
        }
      },
      validatenumbrtrans() {
        if (this.form.personal3.numTrans !== null) {
          this.form.personal3.numTrans = this.form.personal3.numTrans
            .toString()
            .slice(0, 11);
        }
      },
      validatevlumtrans() {
        if (this.form.personal3.volTrans !== null) {
          this.form.personal3.volTrans = this.form.personal3.volTrans
            .toString()
            .slice(0, 11);
        }
      },
      validateSSNNO() {
        if (this.form.personal6.ssn_no !== null) {
          this.form.personal6.ssn_no = this.form.personal6.ssn_no
            .toString()
            .slice(0, 11);
        }
      },
      validatehomenumber() {
        // if (this.form.personal1.homePhoneNum.toString().length >=9 && this.form.personal1.homePhoneNum !== null) {
        //   this.form.personal1.homePhoneNum = this.form.personal1.homePhoneNum.toString().slice(0, 13);
        // }
        if (
          this.form.personal1.homePhoneNum !== null &&
          this.form.personal1.homePhoneNum.toString().length < 7
        ) {
          this.ispassIssuenumberInvalid = true;
        } else {
          this.ispassIssuenumberInvalid = false;
          if (this.form.personal1.homePhoneNum !== null) {
            this.form.personal1.homePhoneNum = this.form.personal1.homePhoneNum
              .toString()
              .slice(0, 13);
          }
        }
      },
      validatenationalidentification() {
        if (this.form.personal1.nationalIdNum !== null) {
          this.form.personal1.nationalIdNum = this.form.personal1.nationalIdNum
            .toString()
            .slice(0, 11);
          this.errorpart = true;
          setTimeout(() => {
            this.errorpart = false;
          }, 10000);
        } else {
          this.errorpart = false;
        }
      },
      validatepassportnumber() {
        if (this.form.personal1.passportNum !== null) {
          this.form.personal1.passportNum = this.form.personal1.passportNum
            .toString()
            .slice(0, 10);
        }
      },
      validatemobnumber() {
        if (
          this.form.personal1.mobileNum !== null &&
          this.form.personal1.mobileNum.toString().length < 7
        ) {
          this.ispassnumberInvalid = true;
        } else {
          this.ispassnumberInvalid = false;
          if (this.form.personal1.mobileNum !== null) {
            this.form.personal1.mobileNum = this.form.personal1.mobileNum
              .toString()
              .slice(0, 13);
          }
        }
      },
      validatemobnumber4() {
        if (
          this.form.personal8.appointnominee_pno2 !== null &&
          this.form.personal8.appointnominee_pno2.toString().length < 7
        ) {
          this.ispassnumberInvalid = true;
        } else {
          this.ispassnumberInvalid = false;
          if (this.form.personal8.appointnominee_pno2 !== null) {
            this.form.personal8.appointnominee_pno2 =
              this.form.personal8.appointnominee_pno2.toString().slice(0, 13);
          }
        }
      },
      validatemobnumber6() {
        if (
          this.form.personal8.relationbenifit3 !== null &&
          this.form.personal8.relationbenifit3.toString().length < 7
        ) {
          this.ispassnumberInvalid = true;
        } else {
          this.ispassnumberInvalid = false;
          if (this.form.personal8.relationbenifit3 !== null) {
            this.form.personal8.relationbenifit3 =
              this.form.personal8.relationbenifit3.toString().slice(0, 13);
          }
        }
      },
      validatemobnumber5() {
        if (
          this.form.personal8.appointnominee_pno1 !== null &&
          this.form.personal8.appointnominee_pno1.toString().length < 7
        ) {
          this.ispassnumberInvalid5 = true;
        } else {
          this.ispassnumberInvalid5 = false;
          if (this.form.personal8.appointnominee_pno1 !== null) {
            this.form.personal8.appointnominee_pno1 =
              this.form.personal8.appointnominee_pno1.toString().slice(0, 13);
          }
        }
      },
      validatemobnumber10() {
        if (
          this.form.personal8.appointnominee_pno3 !== null &&
          this.form.personal8.appointnominee_pno3.toString().length < 7
        ) {
          this.ispassnumberInvalid10 = true;
        } else {
          this.ispassnumberInvalid10 = false;
          if (this.form.personal8.appointnominee_pno3 !== null) {
            this.form.personal8.appointnominee_pno3 =
              this.form.personal8.appointnominee_pno3.toString().slice(0, 13);
          }
        }
      },
      validatemobnumber2() {
        if (
          this.form.personal2.user_w_phone_no !== null &&
          this.form.personal2.user_w_phone_no.toString().length < 7
        ) {
          this.ispassnumberInvalid = true;
        } else {
          this.ispassnumberInvalid = false;
          if (this.form.personal2.user_w_phone_no !== null) {
            this.form.personal2.user_w_phone_no =
              this.form.personal2.user_w_phone_no.toString().slice(0, 13);
          }
        }
      },
      validatemobnumber3() {
        if (
          this.form.personal2.user_phone_no !== null &&
          this.form.personal2.user_phone_no.toString().length < 7
        ) {
          this.ispassnumberInvalid2 = true;
        } else {
          this.ispassnumberInvalid2 = false;
          if (this.form.personal2.user_phone_no !== null) {
            this.form.personal2.user_phone_no = this.form.personal2.user_phone_no
              .toString()
              .slice(0, 13);
          }
        }
      },
      validateDrivingIdNum() {
        if (this.form.personal1.drivingIdNum !== null) {
          // Limit to 6 digits
          this.form.personal1.drivingIdNum = this.form.personal1.drivingIdNum
            .toString()
            .slice(0, 13);
        }
      },
      isValidationError() {
        // Check if at least one of the three sections is filled
        const nationalIdNumFilled = !!this.form.personal1.nationalIdNum;
        const drivingIdNumFilled = !!this.form.personal1.drivingIdNum;
        const passportNumFilled = !!this.form.personal1.passportNum;

        if (!nationalIdNumFilled && !drivingIdNumFilled && !passportNumFilled) {
          this.errorpartone = true;
          this.errorparttwo = false;

          setTimeout(() => {
            this.errorpartone = false;
          }, 5000);

          return false;
        } else {
          this.errorpartone = false;
          return true;
        }
      },
      isDriverPermitValidationError() {
        // Check if Driver’s Permit No. is filled, and at least one more field is filled
        const drivingIdNumFilled = !!this.form.personal1.drivingIdNum;
        const nationalIdNumFilled = !!this.form.personal1.nationalIdNum;
        const passportNumFilled = !!this.form.personal1.passportNum;

        if (drivingIdNumFilled && !nationalIdNumFilled && !passportNumFilled) {
          this.errorparttwo = true;
          this.errorpartone = false;

          setTimeout(() => {
            this.errorparttwo = false;
          }, 5000);
          return false;
        } else {
          this.errorparttwo = false;
          return true;
        }
      },
      threeFieldcheck() {
        const isFormInvalid = this.isValidationError();
        const isFormInvalid1 = this.isDriverPermitValidationError();
        console.log(
          "ye lo dono aagye hum",
          isFormInvalid,
          "----",
          isFormInvalid1
        );
        if (isFormInvalid && isFormInvalid1) {
          return true;
          // alert('Form is invalid. Please fill in at least one of the three sections.');
        } else {
          return false;
          // alert('Form is valid!');
        }
      },
      // onSubmit() {
      //   if (!this.fileSelected.includes(true)) {
      //     // Show an error message or perform any necessary action
      //     alert('Please select at least one file.');
      //     return;
      //   }

      //   // Form submission logic
      //   // ...
      // },

      // -------------------------------------------SECTION STEPS
      async handleBeforeChange() {
        // Call the first function
        let v = this.validateFormOne();

        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 5000); // Hide after 5 seconds
        }
        // Call the second function
        let sdp;
        let i = this.threeFieldcheck();
        console.log("ye lo aagya i", i);
        try {
          await this.submitDataPersonal();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true && i === true) {
          console.log("EVERYTHING IS GOOD Member declarations -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG Member declarations -----------");
          return false;
        }
      },
      async handleBeforeChange1() {
        // Call the first function
        let v = this.validateFormTwo();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }

        let sdp;
        try {
          await this.submitDataEmployement();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD Member declarations -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG Member declarations -----------");
          return false;
        }
      },
      async handleBeforeChange2() {
        // Call the first function
        let v = this.validateFormThree();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }
        // Call the second function
        let sdp;
        try {
          await this.submitDataAccount();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD Member declarations -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG Member declarations -----------");
          return false;
        }
      },
      async handleBeforeChange3() {
        // Call the first function

        let v = this.validateFormFour();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }

        // Call the second function
        let sdp;
        try {
          await this.submitDataMinor();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG");
          return false;
        }
      },
      async handleBeforeChange4() {
        // Call the first function
        let v = this.validateFormFive();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }

        // Call the second function
        let sdp;
        try {
          await this.submitDataPEP();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD PEP -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG PEP -----------");
          return false;
        }
      },
      async handleBeforeChange5() {
        // Call the first function
        let v = this.validateFormsix();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }

        // Call the second function
        let sdp;
        try {
          await this.submitDataFATCA();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD FATCA-------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG FATCA-----------");
          return false;
        }
      },
      async handleBeforeChange6() {
        //this is for Account/Fees
        // Call the first function
        let v = this.validateFormSeven();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }

        // Call the second function
        let sdp;
        try {
          await this.submitData_accountandfees();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD accountandfees -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG accountandfees -----------");
          return false;
        }
      },
      async handleBeforeChange7() {
        // Call the first function
        let v = this.validateFormEight();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }
        // Call the second function
        let sdp;
        try {
          await this.submitData_appointment_nominee();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD appointment_nominee-------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG appointment_nominee-----------");
          return false;
        }
      },
      async handleBeforeChange8() {
        // Call the first function
        let v = this.validateFormNine();
        if (v === false) {
          this.showErrorMessage = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000); // Hide after 5 seconds
        }
        // Call the second function
        let sdp;
        try {
          await this.submitData_memberdec();
          sdp = true; // API call succeeded
        } catch (error) {
          sdp = false; // API call failed
        }

        // Call `next()` to proceed with the navigation
        // next();

        console.log("HERE", v, sdp);
        if (v === true && sdp === true) {
          console.log("EVERYTHING IS GOOD Member declarations -------------");
          return true;
        } else {
          console.log("SOMTHING WENT WRONG Member declarations -----------");
          return false;
        }
      },
      // async handleBeforeChange9() {
      //   // Call the first function
      //   let v = this.validateFormOne();

      //   // Call the second function
      //   let sdp;
      //   try {
      //     await this.submitDataFATCA();
      //     sdp = true; // API call succeeded
      //   } catch (error) {
      //     sdp = false; // API call failed
      //   }

      //   // Call `next()` to proceed with the navigation
      //   // next();
      //   v = true;
      //   console.log("HERE", v, sdp);
      //   if (v === true && sdp === true) {
      //     console.log("EVERYTHING IS GOOD-------------");
      //     return true;
      //   } else {
      //     console.log("SOMTHING WENT WRONG-----------");
      //     return false;
      //   }
      // },

      onFileChange1(event) {
        this.selectedFile1 = event.target.files[0];
        console.log("national bill", this.selectedFile1);
      },
      onFileChange2(event) {
        this.selectedFile2 = event.target.files[0];
        console.log("driver bill", this.selectedFile2);
      },
      onFileChange3(event) {
        this.selectedFile3 = event.target.files[0];
        console.log("passport bill", this.selectedFile3);
      },
      onFileChange4(event) {
        this.selectedFile4 = event.target.files[0];
        console.log("utitliy bill", this.selectedFile4);
      },
      onFileChange5(event) {
        this.selectedFile5 = event.target.files[0];
        console.log("birthday bill", this.selectedFile5);
      },
      onFileChange6(event) {
        this.selectedFile6 = event.target.files[0];
        console.log("Applicant’s Signature", this.selectedFile6);
      },
      onFileChange7(event) {
        this.selectedFile7 = event.target.files[0];
        console.log("Guardian’s Signature", this.selectedFile7);
      },
      onFileChange8(event) {
        this.selectedFile8 = event.target.files[0];
        console.log("Trustee’s Signature", this.selectedFile8);
      },
      onFileChange9(event) {
        this.selectedFile9 = event.target.files[0];
        console.log("Pay & Slip Signature", this.selectedFile9);
      },

      onComplete() {
        // alert("Yay. Done!");
        this.$router.push("/?submit=yes");
      },
      // -------------------------------------SECTION STEPS END

      // chkStaff(){
      //   var t = localStorage.getItem("userType");
      //   console.log("EMAIL IS THIS radioSelected1 testfghfgdfgdfgd", t);
      //   if(t==="staff"){
      //     console.log(this.app_id);
      //   }
      //   else{
      //     return true
      //   }
      // },
      // onFileChange(event) {
      //   this.files = event.target.files;
      // },
      resetFields() {
        this.showRadio = false;
      },

      // ------------------------------------VALIDATION START
      validateFormOne() {
        if (this.v$.form.personal1.$invalid) {
          this.v$.form.personal1.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal1.$model);
          return false;
        } else {
          return true;
        }
      },
      validateFormTwo() {
        if (this.v$.form.personal2.$invalid) {
          this.v$.form.personal2.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal2.$model);
          if (this.form.personal2.employStatus === "unemployed") {
            if (
              !this.form.personal2.user_employer &&
              !this.form.personal2.user_w_phone_no &&
              this.form.personal2.user_occupation
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      validateFormThree() {
        if (this.v$.form.personal3.$invalid) {
          this.v$.form.personal3.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal3.$model);
          return false;
        } else {
          return true;
        }
      },
      //   validateFormFour() {
      //   if (this.radioSelect1 === 'adult') {
      //     return true;
      //   } else if (this.v$.form.personal4.$invalid) {
      //     this.v$.form.personal4.$touch();
      //     console.log(">>> Validation Failed", this.v$.form.personal4.$model);
      //     return false;
      //   } else {
      //     return true;
      //   }
      // },
      validateFormFour() {
        if (this.v$.form.personal4.$invalid) {
          this.v$.form.personal4.$touch();
          if (
            this.form.personal4.parentName === "" &&
            this.form.personal4.parentId === "" &&
            this.form.personal4.trusteeName === "" &&
            this.form.personal4.trusteeId === "" &&
            this.form.personal4.declaration === ""
          ) {
            return true;
          } else {
            return false;
          }
          // console.log(">>> Validation Failed", this.v$.form.personal4.$model);
          // return false;
        } else {
          return true;
        }
      },
      // validateFormFour() {
      //   if (this.v$.form.personal4.$invalid)
      //   {
      //     this.v$.form.personal4.$touch();
      //     console.log(">>> Validation Failed",this.v$.form.personal4.$model);
      //     return false;
      //   } else {
      //     return true;
      //   }
      // },
      validateFormFive() {
        if (this.v$.form.personal5.$invalid) {
          this.v$.form.personal5.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal5.$model);
          return false;
        } else {
          return true;
        }
      },
      validateFormsix() {
        if (this.v$.form.personal6.$invalid) {
          this.v$.form.personal6.$touch();
          console.log("BEFORE PANDU", this.v$.form.personal6.$touch());
          console.log(">>> Validation Failed", this.v$.form.personal6.$model);
          if (
            this.form.personal6.ssn_no === "" &&
            this.form.personal6.tobagoRadio &&
            this.form.personal6.citizenRadio &&
            this.form.personal6.addressRadio &&
            this.form.personal6.incomeUsRadio
          ) {
            return true;
          } else {
            return false;
          }
          // return false;
        } else {
          return true;
        }
      },
      validateFormSeven() {
        if (this.v$.form.personal7.$invalid) {
          this.v$.form.personal7.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal7.$model);
          return false;
        } else {
          return true;
        }
      },
      validateFormEight() {
        if (this.v$.form.personal8.$invalid) {
          this.v$.form.personal8.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal8.$model);
          return false;
        } else {
          return true;
        }
      },
      validateFormNine() {
        if (this.v$.form.personal9.$invalid) {
          this.v$.form.personal9.$touch();
          console.log(">>> Validation Failed", this.v$.form.personal9.$model);
          return false;
        } else {
          return true;
        }
      },
      //------------------------------------ VALIDATION END
      previewImage(event, id) {
        console.log(id);
        // this.fileSelected= true;
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            var data = {
              value: e.target.result,
              type: input.files[0].name.substring(
                input.files[0].name.lastIndexOf(".") + 1
              ),
            };
            this.preview[id] = data;
            console.log("check the data of files", this.preview[id]);
          };
          this.image[id] = input.files[0];
          console.log(
            input.files[0].name.substring(
              input.files[0].name.lastIndexOf(".") + 1
            )
          );
          reader.readAsDataURL(input.files[0]);
        }
      },
      submitDataInitial() {
        console.log("HELLO FROM SUBMIT DATA");
        var adult = "";
        // Make the API request with the entered email
        var t = localStorage.getItem("accessToken");
        console.log("EMAIL IS THIS radioSelected1", t);
        if (this.radioSelected1 === "adult") {
          adult = true;
        } else {
          adult = false;
        }
        console.log("Adult", adult);
        axios
          .post("/applications/initial_application_submit/", {
            token: t,
            is_adult: adult,
            is_fatca: this.checkFeildInput2,
            is_pep: this.checkFeildInput1,
            risk_cat: this.riskInput,
          })
          .then((response) => {
            // Handle the response
            console.log("Initial DATA SUBMIT successfully:", response.data.id);
            this.app_id = response.data.id;
            this.$refs.anotherButton.click();
            this.startIndex = 0;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
      },

      fetchData() {
        var t = localStorage.getItem("accessToken");
        this.isLoading = true;
        axios
          .post("/applications/applicant_app_status/", { token: t })
          .then((response) => {
            if (response.data.app_data) {
              this.app_id = response.data.app_data.id;
              console.log("APP IDDDDD", this.app_id);
            }
            const status = response.data.app_status;
            console.log(
              "cheack the data came or not for application data ",
              status
            );
            this.form.personal1.email = this.usermail;
            if (status == "section0") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 0;
              console.log(
                "cheack the data came or not for application data 222",
                response.data.app_data.is_adult
              );
              if (response.data.app_data.is_adult === true) {
                console.log("INNNNER IIIFFIFIF");
                this.radioSelected1 = "adult";
              } else {
                this.radioSelected1 = "minor";
              }
              if (response.data.app_data.is_fatca === true) {
                this.checkFeildInput2 = true;
              }
              if (response.data.app_data.is_pep === true) {
                this.checkFeildInput1 = true;
              }
              if (response.data.app_data.risk_cat) {
                this.riskInput = response.data.app_data.risk_cat;
              }
              console.log("step 1 tak to aagya mai", this.radioSelected1);
            } else if (status == "section1") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 1;
            } else if (status == "section2") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 2;
              console.log(
                "cheack the data came or not for application data 222",
                response.data.app_data.is_adult
              );
              if (response.data.app_data.is_adult === true) {
                console.log("INNNNER IIIFFIFIF");
                this.radioSelected1 = "adult";
              } else {
                this.radioSelected1 = "minor";
              }
              if (response.data.app_data.is_fatca === true) {
                this.checkFeildInput2 = true;
              }
              if (response.data.app_data.is_pep === true) {
                this.checkFeildInput1 = true;
              }
              if (response.data.app_data.risk_cat) {
                this.riskInput = response.data.app_data.risk_cat;
              }
            } else if (status == "section3") {
              if (response.data.app_data.is_adult === true) {
                console.log("INNNNER IIIFFIFIF");
                this.radioSelected1 = "adult";
              } else {
                this.radioSelected1 = "minor";
              }
              if (response.data.app_data.is_fatca === true) {
                this.checkFeildInput2 = true;
              }
              if (response.data.app_data.is_pep === true) {
                this.checkFeildInput1 = true;
              }
              if (response.data.app_data.risk_cat) {
                this.riskInput = response.data.app_data.risk_cat;
              }
              console.log("step 3 tak to aagya mai", this.radioSelected1);
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 3;
            } else if (status == "section4") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 4;
            } else if (status == "section5") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 5;
            } else if (status == "section6") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 6;
            } else if (status == "section7") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 7;
            } else if (status == "section8") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 8;
            } else if (status == "section9") {
              this.isLoading = false;
              this.showRadio = true;
              this.startIndex = 9;
            } else {
              this.isLoading = false;
              this.showRadio = false;
            }
          })
          .catch((error) => {
            console.error("yes i m error ", error);
          });
      },
      //----------------------------------------API FORM SECTION START
      submitDataPersonal() {
        var t = localStorage.getItem("accessToken");
        let r = false;
        this.form.personal9.appliname = `${this.form.personal1.firstName} ${this.form.personal1.surname}`;
        console.log("HELLO FROM SUBMIT DATA Personal1");
        const formData = new FormData();
        var formdata1 = {
          national_data: this.selectedFile1,
          utility_data: this.selectedFile4,
          // driver_data:this.selectedFile2,
          // passport_data:this.selectedFile3,
          // utility_data:this.selectedFile4,
          token: t,
          app_id: this.app_id,
          branch: this.form.personal1.branchName,
          // member_no: this.form.personal1.memberNum,
          // person_no: this.form.personal1.personNum,
          title: this.form.personal1.title,
          surname: this.form.personal1.surname,
          first_name: this.form.personal1.firstName,
          middle_name: this.form.personal1.middleName,
          home_add: this.form.personal1.address,
          // geo_code: this.form.personal1.geoCode,
          // mailing_add: this.form.personal1.mailingAddress,
          address_line: this.form.personal1.address_line,
          address_line_two: this.form.personal1.address_line_two,
          city_name: this.form.personal1.city_name,
          state_name: this.form.personal1.state_name,
          zip_code: this.form.personal1.zip_code,
          country_name: this.form.personal1.country_name,
          dob: this.form.personal1.dob,
          gender: this.form.personal1.gender,
          dependents: this.form.personal1.numOfDependents,
          place_of_birth: this.form.personal1.placeOfdob,
          nationality: this.form.personal1.nationality,
          marital_status: this.form.personal1.maritalStatus,
          // National ID section
          national_id: this.form.personal1.nationalIdNum,
          national_id_issue_date: this.form.personal1.nidIssueDate,
          national_id_expiry_date: this.form.personal1.nidExpiry,
          national_id_country: this.form.personal1.nationalIssuance,
          national_id_doc: this.selectedFile1,
          // Driving License Section
          driver_permit: this.form.personal1.drivingIdNum,
          driver_permit_issue_date: this.form.personal1.didIssueDate,
          driver_permit_expiry_date: this.form.personal1.didExpiry,
          driver_permit_country: this.form.personal1.driverIssuance,
          driver_permit_doc: this.selectedFile2,
          // Passport Section
          passport_no: this.form.personal1.passportNum,
          passport_no_issue_date: this.form.personal1.passIssueDate,
          passport_no_expiry_date: this.form.personal1.passExpiry,
          passport_no_country: this.form.personal1.passportIssuance,
          passport_no_doc: this.selectedFile3,
          // DOB certificate Section
          dob_no: this.form.personal1.birthIdNum,
          dob_issue_country: this.form.personal1.birthIssuance,
          dob_doc: this.selectedFile5,
          pay_slip: this.selectedFile9,

          home_mob_no: this.form.personal1.homePhoneNum,
          home_mob_no_code: this.form.personal1.homePhoneNumcode,
          mob_no: this.form.personal1.mobileNum,
          mob_no_code: this.form.personal1.mobileNumcode,
          email: this.form.personal1.email,
        };
        Object.keys(formdata1).forEach((key) => {
          formData.append(key, formdata1[key]);
        });

        axios
          .post("/applications/personal_information_submit/", formData)
          .then((response) => {
            // Handle the response
            this.form.personal9.appliname = `${this.form.personal1.firstName} ${this.form.personal1.surname}`;
            console.log("Personal Information Submitted Successfully:", response);
            console.log(
              "Personal ------part 2------------:",
              this.form.personal1.mobileNumcode,
              this.form.personal1.homePhoneNumcode,
              this.form.personal1.mobileNum,
              this.form.personal1.homePhoneNum
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });

        return r;
      },

      // submitgetid() {
      //   var t = localStorage.getItem("accessToken");
      //   axios.post("/applications/get_id_with_token/",{'token':t})
      //     .then((response) => {
      //       console.log("get_id_with_token Successfully: --------------------------", response);

      //     })
      //     .catch((error) => {

      //       console.error("Error sending email:", error);
      //     });
      // },

      submitDataEmployement() {
        // this is for Employment Information
        console.log("HELLO FROM SUBMIT DATA EMPLOYEMENZT");
        var t = localStorage.getItem("accessToken");
        let r = false;
        console.log(
          "HELLO FROM",
          this.form.personal2.employStatus,
          this.form.personal2.user_w_phone_no,
          this.form.personal2.user_w_phone_nocode
        );
        axios
          .post("/applications/employment_information_submit/", {
            token: t,
            app_id: this.app_id,
            emp_status: this.form.personal2.employStatus,
            pay_freq: this.form.personal2.frequencyStatus,
            avg_monthly_inc: this.form.personal2.averageIncm,
            occupation: this.form.personal2.user_occupation,
            employer: this.form.personal2.employer,
            employer_add: this.form.personal2.user_employer,
            work_phone: this.form.personal2.user_w_phone_no,
            work_phonecode: this.form.personal2.user_w_phone_nocode,
            ext: this.form.personal2.user_phone_no,
            school: this.form.personal2.user_isstudent,
            emp_period:
              this.form.personal2.user_howlong +
              " " +
              this.form.personal2.user_howlong2,
          })
          .then((response) => {
            // Handle the response
            console.log("Employer Information Submitted Successfully:", response);
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitDataAccount() {
        // this is for Account Activity
        console.log("HELLO FROM SUBMIT DATA EMPLOYEMENZT");
        var t = localStorage.getItem("accessToken");
        let r = false;
        axios
          .post("/applications/account_activity_submit/", {
            token: t,
            app_id: this.app_id,
            how_account_funded: this.form.personal3.accFunded,
            member_any_credit_union: this.form.personal3.accountActi,
            member_any_credit_union_desc: this.form.personal3.accountActiDesc,
            other_details: this.form.personal3.other_details,
            no_of_transactions: this.form.personal3.numTrans,
            vol_of_transactions: this.form.personal3.volTrans,
            socuce_of_income: this.form.personal3.socuceofincome,
            area_of_details: this.form.personal3.areaofdetails,
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully:",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitDataMinor() {
        console.log("HELLO FROM SUBMIT DATA MINOR");
        var t = localStorage.getItem("accessToken");
        let r = false;
        this.form.personal9.nameofparent = this.form.personal4.parentName;
        this.form.personal9.nameoftrustee = this.form.personal4.trusteeName;
        axios
          .post("/applications/minors_only_submit/", {
            token: t,
            app_id: this.app_id,
            parent_name: this.form.personal4.parentName,
            parent_id_no: this.form.personal4.parentId,
            trustee: this.form.personal4.trusteeName,
            trustee_id_no: this.form.personal4.trusteeId,
            dec: this.form.personal4.declaration,
            advisor: this.form.personal4.advisor,
            // witness:
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully:",
              response
            );
            this.trustee_id = response.data.section_d4.incare_g.id;
            this.gurdian_id = response.data.section_d4.incare_t.id;
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitDataPEP() {
        console.log("HELLO FROM SUBMIT DATA MINOR PEP ------");
        var t = localStorage.getItem("accessToken");
        let r = false;
        axios
          .post("/applications/politically_exposed_persons/", {
            token: t,
            app_id: this.app_id,
            prom_entrust: this.form.personal5.entrustedRadio,
            immd_famly: this.form.personal5.memberOfRadio,
            close_prsonl: this.form.personal5.referredRadio,
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully PEP:",
              this.app_id,
              this.form.personal5.entrustedRadio,
              this.form.personal5.memberOfRadio,
              this.form.personal5.referredRadio
            );
            console.log(
              "Employer Account Information Submitted Successfully PEP:",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitDataFATCA() {
        console.log("HELLO FROM SUBMIT DATA MINOR  FATCA ------");
        var t = localStorage.getItem("accessToken");
        let r = false;
        // let ss = this.form.personal6.ssn_no
        // let ssss ="ssn_null"
        // if(ss){
        //   ssss = ss
        // }
        // console.log("YES HAI PANDU",ssss)
        axios
          .post("/applications/foreign_account_tax/", {
            token: t,
            app_id: this.app_id,
            other_citizen: this.form.personal6.tobagoRadio,
            us_citizen: this.form.personal6.citizenRadio,
            ssn_no: this.form.personal6.ssn_no,
            us_address: this.form.personal6.addressRadio,
            givin_instruct: this.form.personal6.incomeUsRadio,
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully FATCA :",
              this.app_id,
              this.form.personal6.tobagoRadio,
              this.form.personal6.citizenRadio,
              this.form.personal6.ssn_no,
              this.form.personal6.addressRadio,
              this.form.personal6.incomeUsRadio
            );
            console.log(
              "Employer Account Information Submitted Successfully FATCA :",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitData_accountandfees() {
        //this is for Account/Fees
        console.log("HELLO FROM SUBMIT DATA MINOR accountandfees ------");
        var t = localStorage.getItem("accessToken");
        let r = false;
        axios
          .post("/applications/account_and_fees/", {
            token: t,
            app_id: this.app_id,

            account_number_one: this.form.personal7.account_one,
            account_number_two: this.form.personal7.account_two,
            account_number_three: this.form.personal7.account_three,
            shares_amount: this.form.personal7.shares_amount,
            special_shares_amount: this.form.personal7.special_shares_amount,
            deposit_ac_amount: this.form.personal7.deposit_ac_amount,
            reg_fee_amount: this.form.personal7.reg_fee_amount,
            total_amount: this.form.personal7.total_amount,
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully accountandfees:",
              this.app_id,
              this.form.personal7.account_one,
              this.form.personal7.account_two,
              this.form.personal7.account_three,
              this.form.personal7.shares_amount,
              this.form.personal7.special_shares_amount,
              this.form.personal7.deposit_ac_amount,
              this.form.personal7.reg_fee_amount,
              this.form.personal7.total_amount
            );
            console.log(
              "Employer Account Information Submitted Successfully accountandfees:",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitData_appointment_nominee() {
        console.log("HELLO FROM SUBMIT DATA MINOR  appointment_nominee ------");
        var t = localStorage.getItem("accessToken");
        let r = false;
        axios
          .post("/applications/appointment_of_nominee/", {
            token: t,
            app_id: this.app_id,

            appointnominee_name1: this.form.personal8.appointnominee_name1,
            appointnominee_pno1: this.form.personal8.appointnominee_pno1,
            appointnominee_pno1code: this.form.personal8.appointnominee_pno1code,
            appointnominee_rel1: this.form.personal8.appointnominee_rel1,
            appointnominee_otherel: this.form.personal8.appointnominee_otherel,
            appointnominee_address1: this.form.personal8.appointnominee_address1,
            appointnominee_dob1: this.form.personal8.appointnominee_dob1,
            appointnominee_email1: this.form.personal8.appointnominee_email1,

            appointnominee_name2: this.form.personal8.appointnominee_name2,
            appointnominee_pno2: this.form.personal8.appointnominee_pno2,
            appointnominee_pno2code: this.form.personal8.appointnominee_pno2code,
            appointnominee_rel2: this.form.personal8.appointnominee_rel2,
            appointnominee_othere2: this.form.personal8.appointnominee_othere2,
            appointnominee_address2: this.form.personal8.appointnominee_address2,
            appointnominee_dob2: this.form.personal8.appointnominee_dob1,
            appointnominee_email2: this.form.personal8.appointnominee_email1,

            hereRadioType: this.form.personal8.hereRadioType,
            trusteeRadioo: this.form.personal8.trusteeRadioo,

            relationbenifit3: this.form.personal8.relationbenifit3,
            appointnominee_othere3: this.form.personal8.appointnominee_othere3,
            appointnominee_name3: this.form.personal8.appointnominee_name3,
            appointnominee_email3: this.form.personal8.appointnominee_email3,
            appointnominee_pno3: this.form.personal8.appointnominee_pno3,
            appointnominee_dob3: this.form.personal8.appointnominee_dob3,
            appointnominee_address3: this.form.personal8.appointnominee_address3,
          })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully appointment_nominee:",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },
      submitData_memberdec() {
        console.log("HELLO FROM SUBMIT DATA MINOR  appointment_nominee ------");
        var t = localStorage.getItem("accessToken");
        let r = false;

        const formData = new FormData();
        var formdata1 = {
          token: t,
          app_id: this.app_id,
          hereRadioType_one: this.form.personal9.hereRadioType_one,
          datename8: this.form.personal9.datename8,
          applicant_sign: this.selectedFile6,
          guardian_sign: this.selectedFile7,
          truste_sign: this.selectedFile8,

          parent_name: this.form.personal4.parentName,
          parent_id_no: this.form.personal4.parentId,
          trustee: this.form.personal4.trusteeName,
          trustee_id_no: this.form.personal4.trusteeId,
        };
        Object.keys(formdata1).forEach((key) => {
          formData.append(key, formdata1[key]);
        });

        axios
          .post("/applications/members_declaration/", formData)

          // axios
          //   .post("/applications/members_declaration/", {
          //     token: t,
          //     app_id: this.app_id,

          //     hereRadioType_one: this.form.personal9.hereRadioType_one,
          //     datename8: this.form.personal9.datename8,
          //     // appointnominee_rel1:this.form.personal9.appointnominee_rel1,
          //     // appointnominee_address1:this.form.personal9.appointnominee_address1,
          //     // appointnominee_gcode1:this.form.personal9.appointnominee_gcode1,
          //   })
          .then((response) => {
            // Handle the response
            console.log(
              "Employer Account Information Submitted Successfully Member declarations:",
              response
            );
            r = true;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
        return r;
      },

      incSlab() {
        this.isLoading = true;
        axios
          .get("/applications/avg_inc_slab_new/")
          .then((response) => {
            const status = response.data.inc_data;
            console.log(
              "cheack the data came or not for application  INCOME data ",
              status
            );
            this.optionsList = response.data.inc_data;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      // get_user_email() {
      //   axios.get('applications/get_user_email/')
      //   .then(response => {
      //     this.backendValue = response.data;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
      // },
      //----------------------------------------API FORM SECTION END
    },
  };
</script>